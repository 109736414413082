<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <TopNavigation title="" hideSecondNavbar="true" :desc="doc.folderName"/>

    <div class="contentDiv"> 
        <div v-if="urlEncode === ''" class="mainBoxBorderless mainBox65">
            <div class="contentBox border shadow-sm">
                <div  class="row p-4">
                    <div class="col-5 col-lg-4 mb-2">
                        <fa icon="triangle-exclamation" style="font-size: 120px"/>
                    </div>
                    <div class="col-7 col-lg-8 mb-2">
                        <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                        <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                    </div>
                    <div class="col-12"><hr class="my-3"/></div>
                    <div class="col-12 text-center">
                        <button class="btn btn-secondary" @click="router.push('/d/' + route.params.documentId)">
                            <fa icon="folder" size="lg" class="me-2" />Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">

            <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
                <fa icon="chevron-right" class="text-white" />
            </span>

            <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
                <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
            </span>

            <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1 toolbarSignFloat" :class="isSolidBg === true ? 'toolbarSolid' : 'toolbarSemi'" style="z-index: 2">
                <div id="accordion" class="pt-1 ps-1">
                    <div class="card mb-2">

                        <div class="card-header">
                            <div class="d-grid pt-1 px-2">
                                <!-- <button class="btn btn-secondary btn-sm btn-block fw-normal" @click="router.push('/d/' + route.params.documentId)">Back</button> -->
                                <div class="btn-group">
                                    <button type="button" @click="router.push('/d/' + route.params.documentId)" class="btn btn-secondary">
                                        <fa icon="chevron-left" class="me-2" />Back
                                    </button>
                                    <!-- <button type="button" @click="toggleOthers = !toggleOthers" class="btn btn-secondary">
                                        <Popper class="popperDark" arrow hover :content="toggleOthers === true ? 'Show more' : 'Hide more'">
                                            <fa icon="ellipsis" />
                                        </Popper>
                                    </button> -->
                                    <button type="button" @click="isSolidBg = !isSolidBg" class="btn btn-secondary">
                                        <Popper class="popperDark" arrow hover :content="isSolidBg === true ? 'Solid background' : 'Transparent background'">
                                            <span><fa icon="screwdriver-wrench" class="me-2" />Toolbar</span>
                                        </Popper>
                                    </button>
                                </div> 
                            </div>
                        </div>

                        <div id="left1" class="collapse show" >
                            <div class="card-body">
                                <div>
                                    <table align="center" border="0" width="100%">
                                        <tr v-if="editPage === false">
                                            <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1" /></td>
                                            <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)"><fa icon="angle-double-left" /></td>
                                            <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left" /></td>
                                            <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left" /></td>
                                            <td><span class="isLinkPrimary" @click="editPage = true">{{ goToPage.toLocaleString() }}</span> / {{ pageCount.toLocaleString() }}</td>
                                            <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right" /></td>
                                            <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right" /></td>
                                            <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" /></td>
                                            <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right" /></td>
                                        </tr>
                                        <tr v-else>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <span class="input-group input-group-xs" style="width: 150px">
                                                        <span class="input-group-text">Go to page</span>
                                                        <input type="text" v-model="goToPage" autofocus class="form-control form-control-xs" @input="navigatePage(); updPageNum(page)" @focusout="editPage = false" @focus="selectText">
                                                    </span>
                                                    <span class="ps-2 pt-1">/ {{ pageCount.toLocaleString() }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                
                                <div class="pb-2">
                                    <table align="center" border="0">
                                        <tr> 
                                            <td  width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>
                                            <td v-if="scale === 1"  width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                            <td v-else  width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                            <td  width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Other signee not allow to approve / reject if one of signee already rejecetd -->
                    <div v-if="toggleOthers === false && showObj === true && arrRejectedBySignee && arrRejectedBySignee.length === 0" class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left0" style="width: 100%" @click="showBoxAction = !showBoxAction">Action
                                <span class="float-end" :hidden="showBoxAction === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxAction === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="left0" class="collapse show">
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="row px-2">
                                                    <div class="col-6 p-0 d-grid pe-1"><button class="btn btn-secondary btn-sm btn-block" data-bs-toggle="modal" :data-bs-target="'#mdlReject'">Reject</button></div>
                                                    <div class="col-6 p-0 d-grid"><button class="btn btn-info btn-sm btn-block text-white" data-bs-toggle="modal" :data-bs-target="'#' + mdlFirstStep">Sign</button></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="row pt-2 px-2">
                                                    <div class="col-12 p-2 border shadow-sm" style="border-radius: 5px">
                                                        <div><fa icon="lightbulb" size="lg" /> Tips</div>
                                                        <div class="lblTiny">You may resize the signature box to fit your content before signing the document.</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="toggleOthers === false && doc && docShred === 'PENDING' && docShredApproval === 'PENDING'" class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left1" style="width: 100%" @click="showBoxShredApproval = !showBoxShredApproval">Shred Approval
                                <span class="float-end" :hidden="showBoxShredApproval === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxShredApproval === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="left1" class="collapse show">
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="row">
                                                    <div class="lblTiny">This document requires your approval to be shredded permanently.</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="row px-2">
                                                    <div class="col-6 p-0 d-grid pe-1"><button class="btn btn-secondary btn-sm btn-block" data-bs-toggle="modal" :data-bs-target="'#mdlShredReject'">Reject</button></div>
                                                    <div class="col-6 p-0 d-grid"><button class="btn btn-info btn-sm btn-block text-white" data-bs-toggle="modal" :data-bs-target="'#mdlShredApprove'">Shred</button></div>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- Doc Info -->
                    <div class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left3" style="width: 100%" @click="showBoxInfo = !showBoxInfo">Document Information
                                <span class="float-end" :hidden="showBoxInfo === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxInfo === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <!-- <div class="text-danger small">{{hasComboStructureSign}} | {{ hasComboStructureOverlap }}</div>
                        <div class="text-danger small">{{previewCombo}} | {{ previewComboData }}</div> -->

                        <div id="left3" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm" border="0">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <DocStatus :status="docStatus" :shred="docShred" class="ms-0 ps-0 me-2" />
                                                <span v-if="docName">
                                                    <Popper class="popperDark" arrow hover :content="docName">
                                                        <span>{{ (docName.length > 22 ? docName.substring(0, 22) + '...' : docName) }}</span>
                                                    </Popper>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="arrRejectedBySignee && arrRejectedBySignee.length > 0">
                                            <td colSpan="2" class="text-end lblTiny">
                                                <div v-for="r in arrRejectedBySignee" :key="r">
                                                    <span class="text-danger fw-bold">REJECTED</span> by 
                                                    <Popper class="popperDark" arrow hover :content="r.signeeId">{{ r.signeeName }}</Popper>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" class="lblTiny text-end">
                                                <span class="lblTiny">Modified </span>
                                                <Popper class="popperDark" arrow hover :content="'Created ' + func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date())">
                                                    <span class="isTooltipsLabel lblTiny fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                                </Popper>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <!-- Signee Reference -->
                    <div v-if="objList && objList.length > 0" class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left4" style="width: 100%" @click="showBoxObj = !showBoxObj">Signee Reference
                                <span class="float-end" :hidden="showBoxObj === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxObj === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>
                        <div id="left4" class="collapse show" >
                            <div class="card-body">
                                <div v-for="o in objList" :key="o" class="color0 mb-2">
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <!-- <span v-if="o.type === 'fullSignature'" class="me-1">
                                                        <fa icon="signature" size="lg" class="me-1" /> Full Signature
                                                    </span> -->
                                                    <span v-if="o.type === 'initial'" class="me-1">
                                                        <fa icon="pen-nib" size="lg" class="me-1" /> Initial
                                                    </span>
                                                    <span v-if="o.type === 'stamp'" class="me-1">
                                                        <fa icon="stamp" size="lg" class="me-1" /> Stamp
                                                    </span>
                                                    <span v-if="o.type === 'comboSignature'" class="me-1">
                                                        <fa icon="signature" size="lg" class="me-1" /> Signature Block
                                                    </span>
                                                    <span v-if="o.type === 'dateSigned'" class="me-1">
                                                        <fa icon="calendar-alt" size="lg" class="me-1" /> Date Signed
                                                    </span>
                                                    <span v-if="o.type === 'name'" class="me-1">
                                                        <fa icon="user-circle" size="lg" class="me-1" /> Name
                                                    </span>
                                                    <span v-if="o.type === 'email'" class="me-1">
                                                        <fa icon="at" size="lg" class="me-1" /> Email
                                                    </span>
                                                    <span v-if="o.type === 'idPassport'" class="me-1">
                                                        <fa icon="id-card" size="lg" class="me-1" /> ID/Passport
                                                    </span>
                                                    <span v-if="o.type === 'textbox'" class="me-1">
                                                        <fa icon="font" size="lg" class="me-1" /> Textbox
                                                    </span>
                                                    <span v-if="o.type === 'textarea'" class="me-1">
                                                        <fa icon="bars" size="lg" class="me-1" /> Textarea
                                                    </span>
                                                    <span v-if="o.type === 'checkbox'" class="me-1">
                                                        <fa icon="check-square" size="lg" class="me-1" /> Checkbox
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-for="p in o.pages" :key="p" @click="navigatePage(p); updPageNum(p)" class="step-number bg-gray-1">
                                                        <span class="label">{{ p }}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <!-- Signee -->
                    <div class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left5" style="width: 100%" @click="showBoxSignee = !showBoxSignee">Signee(s)
                                <span class="float-end" :hidden="showBoxSignee === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxSignee === true"><fa icon="chevron-down" /></span>
                            </a>
                            
                        </div>

                        <div id="left5" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm" border="0">
                                    <tbody>
                                        <tr v-for="s in docSignee.slice(0, 5)" :key="s">
                                            <td width="45px" class="pt-2">
                                                <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" width="35" height="35" />
                                                <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" :isVerified="s.isVerified" :privilege="s.privilege" displayName="" :isActive="s.status" width="35" height="35" class="ps-2"/> -->
                                            </td>
                                            <td>
                                                <div>{{s.signeeName}}</div>
                                                <div class="lblTiny">{{s.signeeId}}</div>
                                                <div>
                                                    <span v-if="s.signingStatus === 'PENDINGSIGN'" class="lblTiny fw-bold">
                                                        PENDING
                                                    </span>
                                                    <span v-else-if="s.signingStatus === 'REJECTED'">
                                                        <span class="lblTiny fw-bold text-danger">REJECTED</span>
                                                        <span v-if="doc.isEditor === true || doc.jUSignee.isDocumentSignee === true" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelReject">
                                                            <Popper class="popperDark" arrow hover content="Undo sign reject">
                                                                <fa icon="trash" class="text-primary ms-2" />
                                                            </Popper>
                                                        </span>
                                                    </span>
                                                    <span v-else class="lblTiny fw-bold">
                                                        {{s.signingStatus}}
                                                    </span>
                                                    <span v-if="s.privilege !== 'SIGNEE'" class="lblTiny float-end">{{s.privilege}}</span>
                                                </div>
                                                <div v-if="s.comment" class="mt-1 mb-0 border p-2 lblTiny" style="border-radius: 10px;">{{s.comment}}</div>
                                                <div v-if="s.jRemoval" class="mb-0 pt-1 px-2 lblTiny text-end" style="border-radius: 10px;">
                                                    <span v-if="s.jRemoval.status === 'APPROVED'" class="text-warning text-start">
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is approved at ' + func.convDateTimeFormat(s.jRemoval.dateAction, 'datetime')">Shred approved</Popper>
                                                    </span>
                                                    <span v-if="s.jRemoval.status === 'REJECTED'" class="text-warning text-start">
                                                        <span v-if="s.signeeId === getUserId" class="me-2 isLink" data-bs-toggle="modal" :data-bs-target="'#mdlShredCancelReject'">
                                                            <Popper class="popperDark" arrow hover content="Undo shred reject" >
                                                                <fa icon="trash" />
                                                            </Popper>
                                                        </span>
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is rejected at ' + func.convDateTimeFormat(s.jRemoval.dateAction, 'datetime')">Shred rejected</Popper>
                                                    </span>
                                                    <span v-if="s.jRemoval.status === 'PENDING'" class="text-warning text-start">
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is pending for signee\'s approval'">Shred pending</Popper>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-for="s1 in docSignee.slice(5)" :key="s1">
                                            <td width="45px" class="align-middle">
                                                <ImgAvatar :isUser="s1.isUser" :id="s1.jAvatar.userId" displayName="" :isVerified="s1.isVerified" :isActive="s1.status" :privilege="s1.privilege" width="35" height="35" />
                                                <!-- <Avatar :isUser="s1.isUser" :avatar="s1.jAvatar.imgBase64" :isVerified="s1.isVerified" :privilege="s1.privilege" displayName="" :isActive="s1.status" width="35" height="35" class="ps-2"/> -->
                                            </td>
                                            <td>
                                                <div>{{s1.signeeName}}</div>
                                                <div class="lblTiny">{{s1.signeeId}}</div>
                                                <div class="lblTiny">
                                                    <span v-if="s1.signingStatus === 'PENDINGSIGN'" class="fw-bold">PENDING</span>
                                                    <span v-else-if="s1.signingStatus === 'REJECTED'">
                                                        <span class="lblTiny fw-bold text-danger">REJECTED</span>
                                                        <span v-if="doc.isEditor === true || doc.jUSignee.isDocumentSignee === true" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelReject">
                                                            <Popper class="popperDark" arrow hover content="Undo sign reject">
                                                                <fa icon="trash" class="text-primary ms-2" />
                                                            </Popper>
                                                        </span>
                                                    </span>
                                                    <span v-else class="lblTiny fw-bold">
                                                        {{s1.signingStatus}}
                                                    </span>
                                                    <span v-if="s1.privilege !== 'SIGNEE'" class="float-end">{{s1.privilege}}</span>
                                                </div>

                                                <div v-if="s1.comment" class="mt-1 mb-0 border p-2 lblTiny" style="border-radius: 10px;">{{s1.comment}}</div>
                                                <div v-if="s1.jRemoval" class="mb-0 pt-1 px-2 lblTiny text-end" style="border-radius: 10px;">
                                                    <span v-if="s1.jRemoval.status === 'APPROVED'" class="text-warning text-start">
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is approved at ' + func.convDateTimeFormat(s1.jRemoval.dateAction, 'datetime')">Shred approved</Popper>
                                                    </span>
                                                    <span v-if="s1.jRemoval.status === 'REJECTED'" class="text-warning text-start">
                                                        <span v-if="s1.signeeId === getUserId" class="me-2 isLink" data-bs-toggle="modal" :data-bs-target="'#mdlShredCancelReject'">
                                                            <Popper class="popperDark" arrow hover content="Undo shred reject" >
                                                                <fa icon="trash" />
                                                            </Popper>
                                                        </span>
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is rejected at ' + func.convDateTimeFormat(s1.jRemoval.dateAction, 'datetime')">Shred rejected</Popper>
                                                    </span>
                                                    <span v-if="s1.jRemoval.status === 'PENDING'" class="text-warning text-start">
                                                        <Popper class="popperDark" arrow hover :content="'A request to shred document is pending for signee\'s approval'">Shred pending</Popper>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-if="docSignee.length > 5 && !showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">+ {{docSignee.length - 5}} more</td>
                                        </tr>
                                        <tr v-if="docSignee.length > 5 && showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">- {{docSignee.length - 5}} less</td>
                                        </tr>

                                        <tr v-if="doc.remark">
                                            <td colSpan="2" class="pt-2">
                                                <div class="border p-2" style="border-radius: 10px;">
                                                    <div><fa icon="clipboard" size="lg" class="text-brown me-2" /><span class="lblTiny">Public editor note to signee(s)</span></div>
                                                    <div v-html="doc.remark.replaceAll('\n', '<br />')"></div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-if="doc.note && (projPrivilege === 'OWNER' || projPrivilege === 'EDITOR')">
                                            <td colSpan="2" class="pt-2">
                                                <div class="border p-2" style="border-radius: 10px;">
                                                    <div><fa icon="note-sticky" size="lg" class="text-brown me-2" /><span class="lblTiny">Private note to editor(s) only</span></div>
                                                    <div v-html="doc.note.replaceAll('\n', '<br />')"></div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <!-- Activity -->
                    <div class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#right1" style="width: 100%" @click="showBoxActivity = !showBoxActivity">Activities
                                <span class="float-end" :hidden="showBoxActivity === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxActivity === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="right1" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td><span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlActivity">View Activities</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                    </div>

                    <div v-if="doc && doc.jFile && (doc.privilege === 'OWNER' || doc.privilege === 'EDITOR' || doc.privilege === 'SIGNEE' || doc.privilege === 'VIEWER')" class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#right2" style="width: 100%" @click="showBoxDownload = !showBoxDownload">Download
                                <span class="float-end" :hidden="showBoxDownload === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxDownload === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="right2" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="mb-2">
                                                    <div class="isLink" @click="downloadDoc"><fa icon="file-signature" class="me-1" />Original Signed Version</div>
                                                </div>
                                                <div v-if="doc.jFile.dlAuditRptUrl !== undefined && doc.status !== 'VIEWONLY'">
                                                    <div class="isLink" @click="downloadAuditLog"><fa icon="file-alt" class="me-2" />Audit Report</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- TMP <div v-if="toggleOthers === true && isSignOn === true && showObj === true" class="card mb-2 bg-warning"> -->
                    <div v-if="isSignOn === true && showObj === true" class="card mb-2 bg-warning">
                        <div class="card-header bg-warning">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#right3" style="width: 100%" @click="showBoxDelegate = !showBoxDelegate">Delegate To Other
                                <span class="float-end" :hidden="showBoxDelegate === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxDelegate === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="right3" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="mb-2">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" v-model="delegate" placeholder="Email">
                                                    </div>
                                                </div>
                                                <div class="d-grid"><button class="btn btn-secondary btn-sm btn-block" type="button">Apply</button></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- TMP <div v-if="toggleOthers === true && isSignOn === true && showObj === true" class="card mb-2 bg-warning"> -->
                    <div v-if="isSignOn === true && showObj === true" class="card mb-2 bg-warning">
                        <div class="card-header bg-warning">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#right4" style="width: 100%" @click="showBoxRemind = !showBoxRemind">Remind Me Again
                                <span class="float-end" :hidden="showBoxRemind === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxRemind === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="right4" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="mb-2 mx-0 px-0">
                                                    <VueDatePicker v-model="dateReminder" model-type="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" :enable-time-picker="true" auto-apply></VueDatePicker>
                                                    <!-- <Datepicker :modelValue="dateReminder" @update:modelValue="setDateReminder" :enableTimePicker="true" position="left" format="yyyy-MM-dd HH:mm" class="boxCal" autoApply></Datepicker> -->
                                                </div>
                                                <div class="d-grid"><button class="btn btn-secondary btn-sm btn-block" type="button">Apply</button></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- <div v-if="toggleOthers === true && isSignOn === true && showObj === true" class="card mb-2 bg-warning"> -->
                    <div v-if="isSignOn === true && showObj === true" class="card mb-2 bg-warning">
                        <div class="card-header bg-warning">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#right5" style="width: 100%" @click="showBoxSpam = !showBoxSpam">Report Scam
                                <span class="float-end" :hidden="showBoxSpam === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxSpam === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="right5" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td><span class="isLink">Report</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div><!-- accordion end -->

            </div>

            <div :class="contentClass">
                <div class="vh-100 gx-0">
                    <div v-if="showDebug" class="border border-primary small">
                        <!-- <div class="text-info fw-bold" style="font-size: 10px">objList: {{objList.length}}</div>
                        <div class="text-info" style="font-size: 10px" v-for="l in objList" :key="l"> - {{l}}</div> -->
                        <!-- <div v-for="b in docBoxes" :key="b">{{b}}</div> -->
                        <div v-for="b in pgBoxes" :key="b">{{b}}</div>
                    </div>

                    <div v-if="showUIBox === false || isPdfLoaded === false" class="text-center text-info" style="width: 100%; position: fixed; z-index: 1000; top: 100px; font-size: 14px">
                        <fa icon="spinner" size="lg" class="me-2" />Loading...
                    </div>
                   
                    <!-- <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px" class="pdfBg">
                        
                        <div class="parent text-start" id="pdfContainer" ref="pdfContainer" :style="'margin-top: 5px; height: ' + Math.ceil(pdfHeight*uiRatio) + 'px'">
                            <component v-if="showObj=== true" :key="randKey" v-for="b in pgBoxes" :is="newBox" :data="b" :boxClass="signeeSelectedClass" :signee="signeeId" :comboData="comboData"
                            :mdl="mdlFirstStep" :signSrc="signatureSrc" :iniSrc="initialSrc" :staSrc="stampSrc" :signRatio="signRatio" :iniRatio="iniRatio" :staRatio="staRatio"
                            :sWidth="signatureW" :sHeight="signatureH" :iWidth="initialW" :iHeight="initialH" :stWidth="stampW" :stHeight="stampH" :viewWidth="viewWidth"
                                @getResize="getResize" /> 

                                <vue-pdf-embed v-if="urlEncode !== ''" 
                                    id="pdfSign"
                                    ref="pdfSign"
                                    :source="urlEncode"
                                    :page="page"
                                    @rendered="handlePdfRendered"
                                />
                        </div> 
                    </div>-->
                        <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px" class="pdfBg">
                            <div class="parent" id="pdfSignBoxContainer" ref="pdfSignBoxContainer" :style="'width:'+Math.ceil(pdfWidth*scale)+'px; height: '+Math.ceil(pdfHeight*scale)+'px; position: absolute; right: 0px; border: 0px dashed orange'">
                                <!-- <component v-for="b in pgBoxes" :key="randKey" :is="newBox" :data="b" :boxClass="docStatus === 'DRAFT' ? signeeSelectedClass : 'boxDisabled ' + signeeSelectedClass" 
                                    :signee="signeeId" :scale="scale" :showAttr="showAttr"
                                    @getPosition="getPosition" @del="delBox" @getResize="getResize" :isDragable="docStatus === 'DRAFT'" /> -->
                                <component v-if="showObj=== true" :key="randKey" v-for="b in pgBoxes" :is="newBox" :data="b" :boxClass="signeeSelectedClass" :signee="signeeId" :scale="scale" :comboData="comboData"
                                    :mdl="mdlFirstStep" :signSrc="signatureSrc" :iniSrc="initialSrc" :staSrc="stampSrc" :signRatio="signRatio" :iniRatio="iniRatio" :staRatio="staRatio"
                                    :sWidth="signatureW" :sHeight="signatureH" :iWidth="initialW" :iHeight="initialH" :stWidth="stampW" :stHeight="stampH" 
                                        @getResize="getResize" />
                            </div>
                            <canvas v-if="isEncrypted !== true" id="pdf-sign-canvas" style="position: absolute; right: 0px; border: 0px dashed green"></canvas>
                            <div v-if="isEncrypted !== true" id="pdf-sign-annotation-layer" style="position: absolute; right: 0px; border: 0px dashed blue"></div>

                            <div v-if="isEncrypted === true" class="row mt-4">
                                <div class="col-5 col-lg-4 mb-2 text-end">
                                    <fa icon="triangle-exclamation" style="font-size: 120px"/>
                                </div>
                                <div class="col-7 col-lg-8 mb-2">
                                    <div class="fw-bold mb-3" style="font-size: 20px">Encrypted document</div>
                                    <div>Sorry, encrypted PDF is not supported at the moment.</div>
                                </div>
                            </div>
                        </div>

                    </div>
                
            </div>

        </div><!-- row -->
    </div>

    <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlSignature" :nextStep="mdlNextStep">
        <slot>
        <div class="modal-body">
            <div>
                <div v-if="signatureHistList.length === 0" class="mb-2 fw-bold">Create signature from upload/mouse </div>
                <!-- <div v-else class="mb-2 fw-bold">Select signature to add into document</div> -->
                <div v-else-if="signatureHistList.length === 1" class="mb-2 fw-bold">This signature will add to the document</div>
                <div v-else class="mb-2 fw-bold">Click to select a signature to add to the document</div>

                <!-- ORIG signature list 
                <div v-if="signatureHistList && signatureHistList.length > 0" class="px-2">
                    <div>
                        <div v-for="(s, sIdx) in signatureHistList" :key="s.signatureId">
                            <table class="table table-borderless isLink bg-uploaded-img" @click="selectSignature(s)" :class="mdlSignature === s.signatureId ? 'bg-info border' : ''">
                                <tr>
                                    <td class="p-2">
                                        <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" />
                                    </td>
                                    <td class="text-end align-top pe-1" width="40px">
                                        <Popper class="popperDark" arrow hover content="Delete signature">
                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                        </Popper>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div> -->

                <div v-if="signatureHistList && signatureHistList.length === 1" class="row">
                    <div v-for="(s, sIdx) in signatureHistList" :key="s.signatureId" class="col-12 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlSignature === s.signatureId ? 'divSelected':''" @click="selectSignature(s)" style="display: grid; place-items: center; height: 200px; position: relative">
                            <span v-if="mdlSignature === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete signature">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" style="max-height: 190px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="signatureHistList && signatureHistList.length === 2" class="row">
                    <div v-for="(s, sIdx) in signatureHistList" :key="s.signatureId" class="col-6 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlSignature === s.signatureId ? 'divSelected':''" @click="selectSignature(s)" style="display: grid; place-items: center; height: 500px; position: relative">
                            <span v-if="mdlSignature === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete signature">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" style="max-height: 140px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="signatureHistList && signatureHistList.length > 2" class="row">
                    <div v-for="(s, sIdx) in signatureHistList" :key="s.signatureId" class="col-4 mb-3">
                        <div class="w-100 h-100 isLink divHover" :class="mdlSignature === s.signatureId ? 'divSelected':''" @click="selectSignature(s)" style="display: grid; place-items: center; max-height: 100px; height: 100px; position: relative">
                            <span v-if="mdlSignature === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete signature">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" style="max-height: 90px; width: auto; height: auto; display: block;" />
                        </div>
                    </div> 

                    <!-- Data signature: only can use when no comboSignature with stamp -->
                    <!-- <div v-if="isSignOn === true && hasComboStructureOverlap === false" class="col-12 mb-3 bg-warning">

                        <div class="w-100 h-100 isLink divHover" :class="mdlSignature === dataSignatureId ? 'divSelected':''" @click="selectDataSignature(dataSignatureId, 3024, 1134)" style="display: grid; place-items: left; position: relative">
                            <span v-if="mdlSignature === dataSignatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <img src="./../../assets/template/template1_96.png" class="img-responsive" :style="'min-height: 30px; max-height: 120px; height: '+previewComboObj.height+'px ; width: auto; display: block;'" />
                            <div style="font-size: 4pt; font-family: courier; padding-left: 2px" class="text-info">
                                <div>{{ dateSignIdSample.replace(/(.{4})/g, '$1 ').trim() }}</div>
                                <div>{{ func.convDateTimeFormat(new Date(), 'datetimegmt') }}</div>
                            </div>
                            <div :style="'color: black; position: absolute; z-index: 0; top: '+(previewComboData.length <= 2 ? 10 : previewComboData.length*5)+'px; left: '+(previewComboData.length <= 2 ? 10 : previewComboData.length*5)+'px'">
                                <div v-for="c in previewComboData" :key="c">
                                    <div v-if="c !== ''" :style="'line-height: '+ previewComboObj.format.size +'pt; font-family: ' + previewComboObj.format.font + '; font-size: ' + previewComboObj.format.size + 'pt; font-weight: ' + (previewComboObj.format.bold === true ? 'bold' : 'normal') + '; font-style: ' + (previewComboObj.format.italic === true ? 'italic' : 'normal')">{{ c }}</div>
                                </div>
                            </div>
                        </div>
                    </div> --> 

                </div>

                <!-- Data signature: only can use when no comboSignature with stamp -->
                <div v-if="isSignOn === true && hasComboStructureOverlap === false" class="row">
                    <div class="col-12 mb-3 bg-warning">

                        <div class="w-100 h-100 isLink divHover" :class="mdlSignature === dataSignatureId ? 'divSelected':''" @click="selectDataSignature(dataSignatureId, 3024, 1134)" style="display: grid; place-items: left; position: relative">
                            <span v-if="mdlSignature === dataSignatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <img src="./../../assets/template/template1_96.png" class="img-responsive" :style="'min-height: 30px; max-height: 120px; height: '+previewComboObj.height+'px ; width: auto; display: block;'" />
                            <div style="font-size: 4pt; font-family: courier; padding-left: 2px" class="text-info">
                                <div>{{ dateSignIdSample.replace(/(.{4})/g, '$1 ').trim() }}</div>
                                <div>{{ func.convDateTimeFormat(new Date(), 'datetimegmt') }}</div>
                            </div>
                            <div :style="'color: black; position: absolute; z-index: 0; top: '+(previewComboData.length <= 2 ? 10 : previewComboData.length*5)+'px; left: '+(previewComboData.length <= 2 ? 10 : previewComboData.length*5)+'px'">
                                <div v-for="c in previewComboData" :key="c">
                                    <div v-if="c !== ''" :style="'line-height: '+ previewComboObj.format.size +'pt; font-family: ' + previewComboObj.format.font + '; font-size: ' + previewComboObj.format.size + 'pt; font-weight: ' + (previewComboObj.format.bold === true ? 'bold' : 'normal') + '; font-style: ' + (previewComboObj.format.italic === true ? 'italic' : 'normal')">{{ c }}</div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div v-if="signatureHistList && signatureHistList.length > 0" class="mb-3">
                    <table width="100%">
                        <tr>
                            <td width="45%"><hr /></td>
                            <td width="10%" class="text-center">OR</td>
                            <td width="45%"><hr /></td>
                        </tr>
                    </table>
                </div>

                <div class="d-flex">
                    <ul class="nav flex-column nav-pills">
                        <li class="nav-item text-center">
                            <a class="nav-link active" href="#uploadSign" data-bs-toggle="tab">
                                <fa icon="upload"/>Upload
                            </a>
                        </li>
                        <li class="nav-item text-center">
                            <a class="nav-link" href="#typeSign" data-bs-toggle="tab">
                                <fa icon="keyboard"/>
                                <div>Type</div>
                            </a>
                        </li>
                        <li class="nav-item text-center">
                            <a class="nav-link" href="#drawSign" data-bs-toggle="tab">
                                <fa icon="pen"/>
                                <div>Draw</div>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content ps-3 w-100">

                        <div id="uploadSign" class="tab-pane active">
                            <div v-if="isUploadingSign === true" class="mb-2">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <file-pond
                                name="file"
                                class="signature"
                                ref="pondS"
                                label-idle="Drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif"
                                :files="mySignatures"
                                storeAsFile="true"
                                @init="handleSignaturePondInit"
                                @error="uplSignatureError"
                                @processfile="onprocessSignature"
                                @addfile="getSignature"
                                @addfilestart="addSignStart"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <!-- :server="signatureConfig" -->

                            <canvas id="canvasS" ref="canvasS" style="display: none">
                                {{canvasSContext}}
                            </canvas>
                            <div>
                                <img :src="canvasSContextSrc" style="display: none" />
                            </div>
                                
                            <div class="text-center small text-grey">Max image size 1MB
                                <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF only
                            </div>
                        </div>

                        <div id="typeSign" class="tab-pane fade">
                            <div class="pb-2">
                                <canvas id="signCanvas" ref='signCanvas' width="250" height="80" tabindex='0' style="border: 1px solid var(--accent-color);" >{{signText}}</canvas>
                            </div>
                            <div class="pb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" v-model="signText" id="signName" placeholder="Name">
                                    <label for="signName">Type your signature</label>
                                </div>
                            </div>
                            <div class="pb-2">
                                <table width="100%" border="0">
                                    <tr>
                                        <td colspan="3" class="pb-2">
                                            <select v-model="signFontFamily" class="form-select">
                                                <option value="Arial">Arial</option>
                                                <option value="ClickerScript">Clicker Script</option>
                                                <option value="DancingScript">Dancing Script</option>
                                                <option value="ImperialScript">Imperial Script</option>
                                                <option value="LuxuriousScript">Luxurious Script</option>
                                                <option value="MarckScript">Marck Script</option>
                                                <option value="MaShanZheng">Ma Shan Zheng</option>
                                                <option value="MeieScript">Meie Script</option>
                                                <option value="PetitFormalScript">Petit Formal Script</option>
                                                <option value="PinyonScript">Pinyon Script</option>
                                                <option value="Poppins">Poppins</option>
                                                <option value="RougeScript">Rouge Script</option>
                                                <option value="StyleScript">Style Script</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select v-model="signFontSize" class="form-select" >
                                                <option value="38px">38</option>
                                                <option value="40px">40</option>
                                                <option value="42px">42</option>
                                                <option value="44px">44</option>
                                                <option value="46px">46</option>
                                                <option value="48px">48</option>
                                            </select>
                                        </td>
                                        <td width="5px"></td>
                                        <td width="100px"><input type="color" class="form-control form-control-color" v-model="signFontColor" title="Choose a color"></td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button v-if="signText !== ''" type="button" class="btn btn-secondary me-2" @click="signText = ''">Reset</button>
                                <button v-if="signText !== ''" type="button" class="btn btn-secondary" @click="uplSignText">Save</button>
                            </div>
                            
                        </div>

                        <div id="drawSign" class="tab-pane fade">
                            <div class="pb-2">
                                <vue-drawing-canvas ref="vueCanvasDrawS" v-model:image="imgDrawS" 
                                    canvasId="vueCanvasDrawS" 
                                    :width="250"
                                    :height="200"
                                    stroke-type="dash"
                                    line-cap="square"
                                    line-join="round"
                                    :fill-shape="false"
                                    :eraser="false"
                                    :lineWidth="2"
                                    :color="imgDrawSColor"
                                    background-color="#FFFFFF"
                                    :background-image="null"
                                    :watermark="null"
                                    saveAs="png"
                                    :styles="{
                                        border: 'solid 1px var(--accent-color)',
                                    }"
                                    :lock="false"
                                    :additional-images="[]" 
                                />
                            </div>
                            <div>
                                <img :src="imgDrawS" style="display: none" />
                                <canvas id="canvasDrawS" ref="canvasDrawS" style="display: none">{{canvasDrawSContext}}</canvas>
                            </div>
                            <div class="pb-2">
                                <table width="100%" border="0"> 
                                    <tr>
                                        <td width="50px">
                                            <input type="color" class="form-control form-control-color" v-model="imgDrawSColor" title="Choose a color">
                                        </td>
                                        <td class="ps-2">
                                            <button type="button" class="btn btn-secondary me-2" @click.prevent="$refs.vueCanvasDrawS.reset()">Clear</button>
                                            <button type="button" class="btn btn-secondary" @click="uplDrawSign">Save</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>  
        </div><!-- End modal-body -->
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-if="mdlNextStep !== null && mdlSignature !== null" type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlInitial" :nextStep="mdlNextStep">
        <slot>
        <div class="modal-body">
            <div>
                <div v-if="initialHistList.length === 0" class="mb-2 fw-bold">Create initial from upload/mouse</div>
                <div v-else-if="initialHistList.length === 1" class="mb-2 fw-bold">This initial will add to the document</div>
                <div v-else class="mb-2 fw-bold">Click to select a initial to add to the document</div>
                
                <!-- initial
                <div v-if="initialHistList && initialHistList.length > 0" class="px-2">
                    <div>
                        <div v-for="(i, iIdx) in initialHistList" :key="i.signatureId">
                            <table class="table table-borderless isLink bg-uploaded-img" @click="selectInitial(i)" :class="mdlInitial === i.signatureId ? 'bg-info border' : ''">
                                <tr>
                                    <td class="p-2">
                                        <ImgSign :id="i.signatureId" :width="i.width" :height="i.height" label="initial" />
                                    </td>
                                    <td class="text-end align-top pe-1" width="40px">
                                        <Popper class="popperDark" arrow hover content="Delete initial">
                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'initial')"/>
                                        </Popper>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div> -->

                <div v-if="initialHistList && initialHistList.length === 1" class="row">
                    <div v-for="(s, sIdx) in initialHistList" :key="s.signatureId" class="col-12 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlInitial === s.signatureId ? 'divSelected':''" @click="selectInitial(s)" style="display: grid; place-items: center; height: 200px; position: relative">
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <span v-if="mdlInitial === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                                <Popper class="popperDark" arrow hover content="Delete initial">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'initial')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="initial" style="max-height: 190px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="initialHistList && initialHistList.length === 2" class="row">
                    <div v-for="(s, sIdx) in initialHistList" :key="s.signatureId" class="col-6 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlInitial === s.signatureId ? 'divSelected':''" @click="selectInitial(s)" style="display: grid; place-items: center; height: 500px; position: relative">
                            <span v-if="mdlInitial === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete initial">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'initial')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="initial" style="max-height: 140px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="initialHistList && initialHistList.length > 2" class="row">
                    <div v-for="(s, sIdx) in initialHistList" :key="s.signatureId" class="col-4 mb-3">
                        <div class="w-100 h-100 isLink divHover" :class="mdlInitial === s.signatureId ? 'divSelected':''" @click="selectInitial(s)" style="display: grid; place-items: center; max-height: 100px; height: 100px; position: relative">
                            <span v-if="mdlInitial === s.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete initial">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'initial')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="initial" style="max-height: 90px; width: auto; height: auto; display: block;" />
                        </div>
                    </div> 
                </div>
                <!-- new initial -->

                <div v-if="initialHistList && initialHistList.length > 0" class="mb-3">
                    <table width="100%">
                        <tr>
                            <td width="45%"><hr /></td>
                            <td width="10%" class="text-center">OR</td>
                            <td width="45%"><hr /></td>
                        </tr>
                    </table>
                </div>

                <div class="d-flex">
                    <ul class="nav flex-column nav-pills">
                        <li class="nav-item text-center">
                            <a class="nav-link active" href="#uploadInit" data-bs-toggle="tab">
                                <fa icon="upload"/>Upload
                            </a>
                        </li>
                        <li class="nav-item text-center">
                            <a class="nav-link" href="#typeInit" data-bs-toggle="tab">
                                <fa icon="keyboard"/>
                                <div>Type</div>
                            </a>
                        </li>
                        <li class="nav-item text-center">
                            <a class="nav-link" href="#drawInit" data-bs-toggle="tab">
                                <fa icon="pen"/>
                                <div>Draw</div>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content ps-3 w-100">

                        <div id="uploadInit" class="tab-pane active">
                            <div v-if="isUploadingInit === true" class="mb-2">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <file-pond
                                name="file"
                                class="initial"
                                ref="pondI"
                                label-idle="Drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif,"
                                :files="myInitials"
                                storeAsFile="true"
                                @init="handleInitialPondInit"
                                @error="uplInitialError"
                                @processfile="onprocessInitial"
                                @addfilestart="addInitStart"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <!-- :server="initialConfig"  -->

                            <canvas id="canvasI" ref="canvasI" width="250" height="150" style="display: none">
                                {{canvasIContext}}
                            </canvas>
                            <div>
                                <img :src="canvasIContextSrc" width="250" height="150" style="display: none" />
                            </div>

                            <div class="text-center small text-grey">Max image size 1MB
                                <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only
                            </div>
                        </div>

                        <div id="typeInit" class="tab-pane fade">
                            <div class="pb-2">
                                <canvas id="initCanvas"  ref='initCanvas' width="250" height="80" tabindex='0' style="border: 1px solid var(--accent-color);" >{{initText}}</canvas>
                            </div>
                            <div class="pb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" v-model="initText" id="signNameInit" placeholder="Name">
                                    <label for="signNameInit">Type your initial</label>
                                </div>
                            </div>
                            <div class="pb-2">
                                <table width="100%" border="0">
                                    <tr>
                                        <td colspan="3" class="pb-2">
                                            <select v-model="initFontFamily" class="form-select">
                                                <option value="Arial">Arial</option>
                                                <option value="ClickerScript">Clicker Script</option>
                                                <option value="DancingScript">Dancing Script</option>
                                                <option value="ImperialScript">Imperial Script</option>
                                                <option value="LuxuriousScript">Luxurious Script</option>
                                                <option value="MarckScript">Marck Script</option>
                                                <option value="MaShanZheng">Ma Shan Zheng</option>
                                                <option value="MeieScript">Meie Script</option>
                                                <option value="PetitFormalScript">Petit Formal Script</option>
                                                <option value="PinyonScript">Pinyon Script</option>
                                                <option value="Poppins">Poppins</option>
                                                <option value="RougeScript">Rouge Script</option>
                                                <option value="StyleScript">Style Script</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>    
                                        <td>
                                            <select v-model="initFontSize" class="form-select" >
                                                <option value="38px">38</option>
                                                <option value="40px">40</option>
                                                <option value="42px">42</option>
                                                <option value="44px">44</option>
                                                <option value="46px">46</option>
                                                <option value="48px">48</option>
                                            </select>
                                        </td>
                                        <td width="5px"></td>
                                        <td width="100px"><input type="color" class="form-control form-control-color" v-model="initFontColor" title="Choose a color"></td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button v-if="initText !== ''" type="button" class="btn btn-secondary me-2" @click="initText = ''">Reset</button>
                                <button v-if="initText !== ''" type="button" class="btn btn-secondary" @click="uplInitText">Save</button>
                            </div>
                        </div>

                        <div id="drawInit" class="tab-pane fade">
                            <div class="pb-2">
                                <vue-drawing-canvas ref="vueCanvasDrawI" v-model:image="imgDrawI" 
                                    canvasId="vueCanvasDrawI" 
                                    :width="250"
                                    :height="200"
                                    stroke-type="dash"
                                    line-cap="square"
                                    line-join="round"
                                    :fill-shape="false"
                                    :eraser="false"
                                    :lineWidth="2"
                                    :color="imgDrawIColor"
                                    background-color="#FFFFFF"
                                    :background-image="null"
                                    :watermark="null"
                                    saveAs="png"
                                    :styles="{
                                        border: 'solid 1px var(--accent-color)',
                                    }"
                                    :lock="false"
                                    :additional-images="[]" 
                                />
                            </div>
                            <div>
                                <img :src="imgDrawI" style="display: none" />
                                <canvas id="canvasDrawI" ref="canvasDrawI" style="display: none">{{canvasDrawIContext}}</canvas>
                            </div>
                            <div class="pb-2">
                                <table width="100%" border="0">
                                    <tr>
                                        <td width="50px">
                                            <input type="color" class="form-control form-control-color" v-model="imgDrawIColor" title="Choose a color">
                                        </td>
                                        <td class="ps-2">
                                            <button type="button" class="btn btn-secondary me-2" @click.prevent="$refs.vueCanvasDrawI.reset()">Clear</button>
                                            <button type="button" class="btn btn-secondary" @click="uplDrawInit">Save</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div> 
        </div><!-- End modal-body -->
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-if="mdlNextStep !== null && mdlInitial !== null" type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlStamp" :nextStep="mdlNextStep">
        <slot>
        <div class="modal-body">
            <div>
                <div v-if="stampHistList.length === 0" class="mb-2 fw-bold">Create stamp from upload/mouse</div>
                <div v-else-if="stampHistList.length === 1" class="mb-2 fw-bold">This stamp will add to the document</div>
                <div v-else class="mb-2 fw-bold">Click to select a stamp to add to the document</div>
                
                <!-- OLD STAMP 
                <div v-if="stampHistList && stampHistList.length > 0" class="px-2">
                    <div>
                        <div v-for="(i, iIdx) in stampHistList" :key="i.signatureId">
                            <table class="table table-borderless isLink bg-uploaded-img" @click="selectStamp(i)" :class="mdlStamp === i.signatureId ? 'bg-info border' : ''">
                                <tr>
                                    <td class="p-2">
                                        <ImgSign :id="i.signatureId" :width="i.width" :height="i.height" label="stamp"/>
                                    </td>
                                    <td class="text-end align-top pe-1" width="40px">
                                        <Popper class="popperDark" arrow hover content="Delete stamp">
                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'stamp')"/>
                                        </Popper>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div> -->

                <!-- New stamp start -->
                <div v-if="stampHistList && stampHistList.length === 1" class="row">
                    <div v-for="(i, iIdx) in stampHistList" :key="i.signatureId" class="col-12 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlStamp === i.signatureId ? 'divSelected':''" @click="selectStamp(i)" style="display: grid; place-items: center; height: 200px; position: relative">
                            <span v-if="mdlStamp === i.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete stamp">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'stamp')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="i.signatureId" :width="i.width" :height="i.height" label="stamp" style="max-height: 190px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="stampHistList && stampHistList.length === 2" class="row">
                    <div v-for="(i, iIdx) in stampHistList" :key="i.signatureId" class="col-6 mb-2">
                        <div class="w-100 h-100 isLink divHover" :class="mdlStamp === i.signatureId ? 'divSelected':''" @click="selectStamp(i)" style="display: grid; place-items: center; height: 500px; position: relative">
                            <span v-if="mdlStamp === i.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete stamp">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'stamp')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="i.signatureId" :width="i.width" :height="i.height" label="stamp" style="max-height: 140px; width: auto; height: auto; display: block;" />
                        </div>
                    </div>
                </div>
                <div v-if="stampHistList && stampHistList.length > 2" class="row">
                    <div v-for="(i, iIdx) in stampHistList" :key="i.signatureId" class="col-4 mb-3">
                        <div class="w-100 h-100 isLink divHover" :class="mdlStamp === i.signatureId ? 'divSelected':''" @click="selectStamp(i)" style="display: grid; place-items: center; max-height: 100px; height: 100px; position: relative">
                            <span v-if="mdlStamp === i.signatureId" class="text-info" style="position: absolute; z-index: 0; top: 3px; left: 5px"><fa icon="check" /></span>
                            <span class="float-end" style="position: absolute; z-index: 0; top: 3px; right: 5px">
                                <Popper class="popperDark" arrow hover content="Delete stamp">
                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'stamp')"/>
                                </Popper>
                            </span>
                            <ImgSign :id="i.signatureId" :width="i.width" :height="i.height" label="stamp" style="max-height: 90px; width: auto; height: auto; display: block;" />
                        </div>
                    </div> 
                </div>
                <!-- New stamp end -->

                <div v-if="stampHistList && stampHistList.length > 0" class="mb-3">
                    <table width="100%">
                        <tr>
                            <td width="45%"><hr /></td>
                            <td width="10%" class="text-center">OR</td>
                            <td width="45%"><hr /></td>
                        </tr>
                    </table>
                </div>

                <div>
                    <div v-if="isUploadingSta === true" class="mb-2">
                        <fa icon="spinner" size="lg" class="me-2" />Loading...
                    </div>

                    <file-pond
                        name="file"
                        class="stamp"
                        ref="pondSt"
                        label-idle="Drop image"
                        v-bind:allow-multiple="false"
                        accepted-file-types="image/jpeg, image/png, image/gif,"
                        :files="myStamps"
                        storeAsFile="true"
                        @init="handleStampPondInit"
                        @error="uplStampError"
                        @processfile="onprocessStamp"
                        @addfilestart="addStaStart"
                        allowRevert="true"
                        allowRemove="false"
                    />

                    <canvas id="canvasSt" ref="canvasSt" width="250" height="150" style="display: none">
                        {{canvasStContext}}
                    </canvas>
                    <div>
                        <img :src="canvasStContextSrc" width="250" height="150" style="display: none" />
                    </div>
                    <div class="text-center small text-grey">Max image size 1MB
                        <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only
                    </div>
                </div>
            </div> 
        </div><!-- End modal-body -->
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-if="mdlNextStep !== null && mdlStamp !== null" type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlAutoFill" :nextStep="mdlNextStep">
        <slot>
        <div class="modal-body">
            <div>
                <div class="mb-2 fw-bold">Modify your auto-filled field{{ (mdlFieldAuto.length > 1 ? 's' : '') }} below if incorrect</div>
                <table width="100%" border="0">
                    <tr v-for="f in mdlFieldAuto" :key="f.id">
                        <td class="align-top pb-2 pe-2">
                            <div :class="'box color0Bg'" :style="'width: 200px'">
                                <div v-if="f.type === 'dateSigned'" class="p-1">
                                    <fa icon="calendar-alt" size="lg" class="me-1" />
                                    <span>Date Signed <span v-if="f.isMandatory">*</span></span>
                                </div>

                                <div v-if="f.type === 'name'" class="p-1" >
                                    <fa icon="user-circle" size="lg" class="me-1" /> 
                                    <span>Name <span v-if="f.isMandatory">*</span></span>
                                </div>

                                <div v-if="f.type === 'email'" class="p-1" >
                                    <fa icon="at" size="lg" class="me-1" /> 
                                    <span>Email <span v-if="f.isMandatory">*</span></span>
                                </div>

                                <div v-if="f.type === 'idPassport'" class="p-1" >
                                    <fa icon="id-card" size="lg" class="me-1" /> 
                                    <span>ID/Passport <span v-if="f.isMandatory">*</span></span>
                                </div>
                            </div>
                        </td>
                        <td style="padding-bottom: 10px">
                            <div v-if="f.type === 'name'">
                                <table width="100%">
                                    <tr>
                                        <td>
                                            <input v-model="mdlName" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlName)" />
                                        </td>
                                        <td v-if="mdlNameOpt.length > 1" class="ps-1">
                                            <div class="dropdown dropstart">
                                                <button type="button" class="btn btn-sm btn-link" data-bs-toggle="dropdown">
                                                    <fa icon="ellipsis-h" class="text-secondary"/>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" v-for="na in mdlNameOpt" :key="na" @click="mdlName = na">{{na}}</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                
                            </div>
                            <div v-if="f.type === 'email'">
                                <input v-model="mdlEmail" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlEmail)" />
                            </div>
                            <div v-if="f.type === 'idPassport'">
                                
                                <table width="100%">
                                    <tr>
                                        <td>
                                            <input v-model="mdlIdPassport" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlIdPassport)" />
                                        </td>
                                        <td v-if="mdlIdPassportOpt.length > 1" class="ps-1">
                                            <div class="dropdown dropstart">
                                                <button type="button" class="btn btn-sm btn-link" data-bs-toggle="dropdown">
                                                    <fa icon="ellipsis-h" class="text-secondary"/>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" v-for="na in mdlIdPassportOpt" :key="na" @click="mdlIdPassport = na">{{na}}</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                                
                            <div v-if="f.type === 'dateSigned'">
                                Auto record date & time in your timezone....<br>eg. {{ curDateTime }}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-else type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlStandard" :nextStep="mdlNextStep" >
        <slot>
        <div class="modal-body">
            <div>
            <div class="mb-2 fw-bold">Fill in the field{{ (mdlFieldStd.length > 1 ? 's' : '') }} below</div>

            <table width="100%" border="0">
                <tr v-for="s in mdlFieldStd" :key="s.id">
                <td style="padding-bottom: 15px">
                    <div v-if="s.type === 'textbox'">
                        <span class="small pe-1">Textbox:</span><label>{{ s.label }} <span v-if="s.isMandatory">*</span></label>
                        <input type="text" v-model="mdlTextbox[s.id]" @input="updObjSign(s.id, s.type, mdlTextbox[s.id])" class="form-control form-control-sm" :maxlength="s.maxChar" />
                        <span v-if="s.placeholder" class="text-secondary small">{{s.placeholder}}</span>
                        <div v-if="mdlTextbox[s.id]" class="text-grey small text-end">{{ mdlTextbox[s.id].length }} / {{s.maxChar}} char</div>
                        <div v-else class="text-grey small text-end">0 / {{s.maxChar}} char</div>
                    </div>
                    <div v-if="s.type === 'textarea'">
                        <span class="small pe-1">Textarea:</span><label>{{ s.label }} <span v-if="s.isMandatory">*</span></label>
                        <textarea class="form-control form-control-sm" rows="5" v-model="mdlTextarea[s.id]" @input="updObjSign(s.id, s.type, mdlTextarea[s.id])" :maxlength="s.maxChar"></textarea>
                        <span v-if="s.placeholder" class="text-secondary small">{{s.placeholder}}</span>
                        <div v-if="mdlTextarea[s.id]" class="text-grey small text-end">{{ mdlTextarea[s.id].length }} / {{s.maxChar}} char</div>
                        <div v-else class="text-grey small text-end">0 / {{s.maxChar}} char</div>
                    </div>
                    <div v-if="s.type === 'checkbox'">
                        <input type="checkbox" v-model="mdlCheckbox[s.id]" value="1" @change="updObjSign(s.id, s.type, mdlCheckbox[s.id])" class="form-check-input" />
                        <span class="small ps-2 pe-1">Checkbox:</span><label class="form-check-label ms-1">{{ s.label }} <span v-if="s.isMandatory">*</span></label>
                        <div v-if="s.placeholder" class="ms-3 text-secondary small">{{s.placeholder}}</div>
                    </div>
                </td>
                </tr>
            </table>
            </div>
        </div>
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm" data-bs-dismiss="modal">
                <fa icon="signature" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-else type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal title="Confirmation" id="mdlCombo" :nextStep="null">
        <slot>
        <div class="modal-body">

            <div class="row">
                <div class="col-12 fw-bold">
                    Please confirm your information below for signature.
                </div>
                <div class="col-12 fw-bold mb-3">
                    You may update your information in <span class="text-primary isLink" data-bs-dismiss="modal" @click="$router.push({ path: '/auth/preference' })">Preferences</span> page if required.
                </div>

                <div v-if="hasComboFullLegalName" class="col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" disabled="true" v-model="comboData.fullLegalName" id="fullLegalName" placeholder="Full legal name">
                        <label for="fullLegalName">Full legal name</label>
                    </div>
                    <div v-if="validCombo === false && (comboData.fullLegalName === '' || comboData.fullLegalName === null || comboData.fullLegalName === undefined)" class="text-danger small">
                        * Required
                    </div>
                </div>

                <div v-if="hasComboDesignation" class="col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" disabled="true" v-model="comboData.designation" id="designation" placeholder="Designation">
                        <label for="designation">Designation</label>
                    </div>
                    <div v-if="validCombo === false && (comboData.designation === '' || comboData.designation === null || comboData.designation === undefined)" class="text-danger small">
                        * Required
                    </div>
                </div>

                <div v-if="hasComboOrganization" class="col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" disabled="true" v-model="comboData.organization" id="organization" placeholder="Name of organization">
                        <label for="organization">Name of organization</label>
                    </div>
                    <div v-if="validCombo === false && (comboData.organization === '' || comboData.organization === null || comboData.organization === undefined)" class="text-danger small">
                        * Required
                    </div>
                </div>

                <div v-if="hasComboNric" class="col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" disabled="true" v-model="comboData.nric" id="nric" placeholder="NRIC">
                        <label for="nric">NRIC (National Registration Identity)</label>
                    </div>
                    <div v-if="validCombo === false && (comboData.nric === '' || comboData.nric === null || comboData.nric === undefined)" class="text-danger small">
                        * Required
                    </div>
                </div>

                <div v-if="hasComboPassport" class="col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" disabled="true" v-model="comboData.passport" id="passport" placeholder="Passport">
                        <label for="passport">Passport</label>
                    </div>
                    <div v-if="validCombo === false && (comboData.passport === '' || comboData.passport === null || comboData.passport === undefined)" class="text-danger small">
                        * Required
                    </div>
                </div>
               
            </div>
            
        </div>
        <div class="modal-footer">
            <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" :disabled="validCombo === true ? false : true" data-bs-toggle="modal" data-bs-target="#mdlConfirm" data-bs-dismiss="modal">
                <fa icon="signature" size="lg" class="me-2" />Confirm and Sign
            </button>
            <button v-else type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
            </button>
            <button v-if="mdlNextStep === null && validCombo === false" type="button" class="btn btn-secondary" @click="$router.push({ path: '/auth/preference' })" data-bs-dismiss="modal">
                <fa icon="user" size="lg" class="me-2" />Preferences
            </button>
        </div>
        </slot>    
    </Modal>

    <Modal title="Confirm Submission" id="mdlConfirm">
        <slot>
        <div class="modal-body">
            
            <div>By clicking on the 'Submit' button, you confirm that you have read the <span class="fst-italic">Terms & Conditions</span> and agree to be bounded by its contents.</div>

            <div class="mt-2">
                <div>
                    <textarea class="form-control" rows="6" id="comment" name="text" placeholder="Your comment (optional)"></textarea>
                </div>
            </div>

            <div class="mt-2 mb-3">
                <input class="form-check-input" type="checkbox" v-model="isAgree" value="1">
                <span class="ms-2" style="cursor: pointer"  @click="isAgree = true">I agree with the <router-link to="/terms" target="_blank"><span class="text-primary isLink">Terms & Conditions</span></router-link> of SignOn</span>
            </div>

            <div v-if="isSignOn === true" class="mb-3 bg-warning">
                <div class="mb-2">Supplementary attachment(s)</div>
                <div class="mb-3">
                        <file-pond
                            name="attachment"
                            ref="pondAttachment"
                            label-idle="Drop files"
                            v-bind:allow-multiple="true"
                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, text/plain, application/pdf, application/msword"
                            :files="myAttachment"
                            storeAsFile="true"
                            @init="handleAttachmentUploadInit"
                            @error="uplAttachmentError"
                            @addfile="getAttachment"
                            allowRevert="true"
                            allowRemove="false"
                        />
                        <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, TXT, DOC or PDF only</div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-secondary" :class="isAgree === true ? '' : 'disabled'" @click="confirmSign" data-bs-dismiss="modal">Submit</button>
        </div>
        </slot>    
    </Modal>

    <!-- Modal: mdlReject - Reject sign doc --> 
    <div
        class="modal fade"
        id="mdlReject"
        tabindex="-1"
        aria-labelledby="mdlReject"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Reject</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">Attachment</div>
                        <div class="col-12 mb-3">
                                <file-pond
                                    name="attachment"
                                    ref="pondAttachment"
                                    label-idle="Drop files"
                                    v-bind:allow-multiple="true"
                                    accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, text/plain, application/pdf, application/msword"
                                    :files="myAttachment"
                                    storeAsFile="true"
                                    @init="handleAttachmentUploadInit"
                                    @error="uplAttachmentError"
                                    @addfile="getAttachment"
                                    allowRevert="true"
                                    allowRemove="false"
                                />
                                <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, TXT, DOC or PDF only</div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">Comment</div>
                    <div class="col-12 mb-2"><textarea class="form-control" rows="5" v-model="rejectComment"></textarea></div>
                    <div class="col-12 mb-2 small text-grey">Note: You are not allowed to modify after submission.</div>
                </div> 
                <div v-if="rejectComment !== ''" class="modal-footer">
                    <button type="button" @click="addRejectComment" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: mdlReject End --> 

    <!-- Modal: Confirm to remove reject status --> 
    <div class="modal fade" id="mdlDelReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to undo sign reject?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelSignReject">
                        <fa icon="trash" size="lg" class="me-2" />Undo
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Confirm to remove reject End -->

    <!-- Modal: mdlTnc --> 
    <div
        class="modal fade"
        id="mdlTnc"
        tabindex="-1"
        aria-labelledby="mdlTnc"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Terms and Conditions</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">T n C ...</div>
                        
                    </div>
                    
                </div> 
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="times" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: mdlTnc End --> 

    <!-- Modal: mdlActivity --> 
    <div
        class="modal fade"
        id="mdlActivity"
        tabindex="-1"
        aria-labelledby="mdlActivity"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">View Activites</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="auditLogBox">
                        <table class="table table-sm table-hover">
                            <tbody>
                                <tr v-for="audit in doc.jAuditLog" :key="audit">
                                    <td>
                                        <div class="row">
                                            <div class="col-8 mb-2">
                                                <div>{{audit.userId}}</div>
                                                <div class="small">{{ func.convDateTimeFormat(audit.date, 'text')}}</div>
                                            </div>
                                            <div class="col-4 mb-2 text-end">
                                                <div>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'windows'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'windows']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'linux'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'linux']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'ubuntu'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'ubuntu']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'redhat'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'redhat']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'fedora'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'fedora']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'centos'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'centos']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'mac os'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'apple']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                            
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'chrome'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'chrome']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'firefox'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'firefox']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'edge'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'edge']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'mobile safari'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'safari']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>

                                                    <span>
                                                        <Popper class="popperDark" arrow hover :content="audit.jGeoLocation.city + ', ' + audit.jGeoLocation.state + ', ' + audit.jGeoLocation.countryName">
                                                            <fa icon="location-dot" style="font-size: 25px" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 mb-3">{{audit.description}}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="times" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: mdlActivity End --> 

    <!-- Modal: Confirm to reject shred approval --> 
    <div class="modal fade" id="mdlShredReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to reject shred request?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredReject()">
                        <fa icon="ban" size="lg" class="me-2" />Reject
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Confirm to approve/agree shred approval --> 
    <div class="modal fade" id="mdlShredApprove">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Confirm to approve shred request?</div>
                    <div>This document is unrecoverable after shredded.</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredApprove()">
                        <fa icon="trash" size="lg" class="me-2" />Shred
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

     <!-- Modal: Confirm to cancel reject shred approval --> 
    <div class="modal fade" id="mdlShredCancelReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to undo shred reject?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredCancelReject()">
                        <fa icon="trash" size="lg" class="me-2" />Undo
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Confirm to cancel reject shred approval End -->
</template>

<script>
import { ref, inject, computed, onMounted, watch } from 'vue'
// import TopNavigationView from '@/components/TopNavigationView.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRoute, useRouter } from 'vue-router'
// import VuePdfEmbed from 'vue-pdf-embed'
import DragBox from '@/components/DragBoxSign.vue'
import Modal from '@/components/ModalSign.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import DocStatus from '@/components/DocStatus.vue'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgSign from '@/components/ImgSign.vue'
import { useStore } from '@/stores/store'
// import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
// import { degrees, EncryptedPDFError, rgb, StandardFonts } from 'pdf-lib'
import { degrees, PDFDocument, EncryptedPDFError, rgb, StandardFonts } from 'pdf-lib-with-encrypt'
import VueDrawingCanvas from 'vue-drawing-canvas'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// Image Uploader for signature & initial
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

let pdfjsDoc = null // for pdfjslib

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)

export default {
    name: 'ViewProjDoc2',
    components: { TopNavigation, Alert, /* VuePdfEmbed, */ DragBox, Modal, FilePond, Popper, DocStatus, /* Avatar, */ ImgAvatar, ImgSign, degrees, PDFDocument, EncryptedPDFError, rgb, StandardFonts, VueDrawingCanvas, VueDatePicker },
    setup() {

        const showDebug = ref(false)

        const axios = inject('axios')
        const alert = ref([])
        
        const func = funcs
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getUserId } = useStore()

        const isSignOn = ref(false) // detect if from signon Entity
        const isSolidBg = ref(false)
        const toggleOthers = ref(false)

        const url = ref(null)
        const urlEncode = ref(null)
        const showUIBox = ref(false)
        const isPdfLoaded = ref(false)
        const showToolbox = ref(true)
        const showMoreSignee = ref(false)
        const toolboxBtnIcon = ref(null)
        const contentClass = ref('col-12 p-0')
        // const isToolbarFloat = ref(false)
        const signeeSelectedClass = ref('color0')

        const docBoxes = ref([])  // cur signee boxes in doc (all pages)
        const pgBoxes = ref([])   // cur signee boxes in current page

        const doc = ref([])
        const docName = ref(null)
        const docStatus = ref(null)
        const docShred = ref('')    // doc shred status
        const docShredApproval = ref(null)      // signee doc shred approval action to shred the doc
        const docShredApprovalDate = ref(null)  // signee doc shred approval action data
        const docSignee = ref([])
        const isDocSigned = ref(null) // Indicate if me (signee) has signed the document, for PENDING / PENDINGSIGN
        const showObj = ref(false) // Indicate to show object if my turn (PENDINGSIGN) and i not sign yet
        const signeeId = ref(null)
        const objList = ref([])  // unique object type to display in signing modal
        const objSign = ref([]) // signed object to update in backend
        const totalCompulsoryField = ref(0)
        const totalDoc = ref(0)
        const projPrivilege = ref(null)
        const isEncrypted = ref(false)

        const signatureW = ref(0)
        const signatureH = ref(0)
        const initialW = ref(0)
        const initialH = ref(0)
        const stampW = ref(0)
        const stampH = ref(0)
        const signatureSrc = ref(null)
        const initialSrc = ref(null)
        const stampSrc = ref(null)
        const isAgree = ref(0)

        const signMaxW = ref(250)
        const signMaxH = ref(120)
        const iniMaxW = ref(140)
        const iniMaxH = ref(60)
        const staMaxW = ref(250)
        const staMaxH = ref(140)
        const signRatio = ref(0)
        const iniRatio = ref(0)
        const staRatio = ref(0)

        const pdfContainer = ref(null)
        const pdfSign = ref(null)
        const pdfWidth = ref(0)
        const pdfHeight = ref(0)
        const viewWidth = ref(0)    // pdfContainer clientWidth
        const viewHeight = ref(0)
        /* pdfjs | const uiRatioPrev = ref(0)  // previous ratio between UI width and PDF actual width.
        const uiRatio = ref(0) // ratio between UI width and PDF actual width.  */
        const scale = ref(1)
        const browserWidth = ref(0) // browser width
        const docWidth = ref(0) //pt

        const isPdfLoading = ref(null)
        const page = ref(1)
        const pageCount = ref(1)
        const goToPage = ref(1)
        const editPage = ref(false)
        const randKey = ref(1)

        const signatureHistList = ref([])
        const initialHistList = ref([])
        const stampHistList = ref([])

        const showBoxAction = ref(true)
        const showBoxShredApproval = ref(true)
        const showBoxInfo = ref(true)
        const showBoxObj = ref(true)
        const showBoxSignee = ref(true)
        const showBoxOther = ref(true)
        const showBoxActivity = ref(true)
        const showBoxDownload = ref(true)
        const showBoxDelegate = ref(true)
        const showBoxRemind = ref(true)
        const showBoxSpam = ref(true)

        const hasMdlSignature = ref(false)
        const hasMdlInitial = ref(false)
        const hasMdlStamp = ref(false)
        const hasMdlCombo = ref(false)
        const hasMdlAutoFill = ref(false)
        const hasMdlStandard = ref(false)

        const mdlFirstStep = ref(null)
        const mdlNextStep = ref(null)
        const mdlCurStep = ref(0)
        const mdlTotalStep = ref(0)
        const mdlTextbox = ref([])
        const mdlTextarea = ref([])
        const mdlCheckbox = ref([])
        const mdlSignature = ref(null)
        const mdlInitial = ref(null)
        const mdlStamp = ref(null)

        const mdlFieldAuto = ref([]) // auto-filled object in modal content
        const mdlFieldStd = ref([])  // standard object in modal content

        const mdlName = ref(null)
        const mdlEmail = ref(null)
        const mdlIdPassport = ref(null)
        const mdlDateSigned = ref(null)

        const mdlNameOpt = ref([])
        const mdlIdPassportOpt = ref([])
        const curDateTime = ref(null)

        const rejectComment = ref('')

        const signCanvas = ref(null)
        const signContext = ref(null)
        const signText = ref('')
        const signImg = ref(null)
        const signFontFamily = ref('DancingScript')
        const signFontSize = ref('40px')
        const signFontColor = ref('#000000')

        const isUploadingSign = ref(false)
        const isUploadingInit = ref(false)
        const isUploadingSta = ref(false)

        const initCanvas = ref(null)
        const initContext = ref(null)
        const initText = ref('')
        const initImg = ref(null)
        const initFontFamily = ref('DancingScript')
        const initFontSize = ref('40px')
        const initFontColor = ref('#000000')

        const pondS = ref(null)
        const canvasS = ref(null)
        const canvasSContext = ref(null)
        const canvasSContextSrc = ref(null)
        const mySignatures = ref([])

        const pondI = ref(null)
        const canvasI = ref(null)
        const canvasIContext = ref(null)
        const canvasIContextSrc = ref(null)
        const myInitials = ref([])

        const pondSt = ref(null)
        const canvasSt = ref(null)
        const canvasStContext = ref(null)
        const canvasStContextSrc = ref(null)
        const myStamps = ref([])

        const myAttachment = ref([])

        const vueCanvasDrawS = ref(null)
        const imgDrawS = ref(null)
        const imgDrawSColor = ref('#000000')
        const canvasDrawS = ref(null)
        const canvasDrawSContext = ref(null)
        
        const vueCanvasDrawI = ref(null)
        const imgDrawI = ref(null)
        const imgDrawIColor = ref('#000000')
        const canvasDrawI = ref(null)
        const canvasDrawIContext = ref(null)
        
        const comboData = ref({})
        const dateReminder = ref('')

        const hasComboFullLegalName = ref(false)
        const hasComboDesignation = ref(false)
        const hasComboOrganization = ref(false)
        const hasComboNric = ref(false)
        const hasComboPassport = ref(false)
        const hasComboDateSigned = ref(false)

        // comboStructure (sign/signstaoverlap)
        const hasComboStructureSign = ref(false)
        const hasComboStructureOverlap = ref(false)

        const validCombo = ref(true) // Validate if all value required set for combo signature, before proceed to sign
        const delegate = ref(null)

        const arrRejectedBySignee = ref([])
        // signature data preview in modalbox - always take  1st signStructure = sign's comboSignature
        const previewCombo = ref([])
        const previewComboData = ref([])
        const previewComboObj = ref({})
        const dataSignatureId = ref(1)  // hardcoded to 1
        const dateSignIdSample = ref('EA1EF1F09B181EF973EA2EB3A8803120')

        watch([url], async () => {
            if (url.value === undefined || url.value === null || url.value === '') {
                urlEncode.value = ''

            } else {
                // console.info('watch - loadPdfToBase64', url.value)
                // urlEncode.value = await loadPdfToBase64(url.value)
                // urlEncode.value = await loadPdfToBase64(url.value, { ignoreEncryption: true })
                clearAnnotation()
                readDoc(url.value)

            }
        })

        watch([page, scale], async ([newPage, newScale], [oldPage, oldScale]) => {
            // console.info('---- watch page/scale')
            // localStorage.setItem('scale', scale.value)
            clearAnnotation()
            readPage(page.value, scale.value)
        })

        watch([signText, signFontFamily, signFontSize, signFontColor], () => {
            convertSignTextToImg()
        })

        watch([initText, initFontFamily, initFontSize, initFontColor], () => {
            convertInitTextToImg()
        })

        /* watch([isDocSigned], () => {
            if(isDocSigned.value === true) {
                toggleOthers.value = true;
            }
        }) */

        const cancelSignReject = () => {
            axios.put( '/signon/signee/' + route.params.projectId + '/' + route.params.documentId + '/CANCELREJECTED', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is cancelled for reject sign."
                        })

                    } else {
                        func.addLog('sign', 'cancelSignReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'cancelSignReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const shredReject = () => {
            axios.put( '/signon/signee/shred/' + route.params.projectId + '/' + route.params.documentId + '/REJECTED', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is rejected for shred."
                        })

                    } else {
                        func.addLog('sign', 'shredReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const shredApprove = () => {
            axios.put( '/signon/signee/shred/' + route.params.projectId + '/' + route.params.documentId + '/APPROVED', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is rejected for shred."
                        })

                    } else {
                        func.addLog('sign', 'shredReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const shredCancelReject = () => {
            axios.put( '/signon/signee/shred/' + route.params.projectId + '/' + route.params.documentId + '/CANCELREJECTED', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is undo for shred reject."
                        })

                    } else {
                        func.addLog('sign', 'shredCancelReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredCancelReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const selectText = (event) => {
            event.target.select()
        }

        const readDoc = async (url) => {
            // console.info('- - readDoc')
            isPdfLoaded.value = false

            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            // const pdfDoc = await PDFDocument.load(existingPdfBytes)
            const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
            const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true })

            /* WORKING: pass in new URL method
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                const newurl =  window.URL.createObjectURL(blob)
                console.info('newurl', newurl)

                urlEncode.value = Pdfvuer.createLoadingTask(newurl)
                urlEncode.value.then(pdf => {
                    pageCount.value = pdf.numPages
                    isPdfLoaded.value = true
                })
            } */

            const loadingTask = pdfjsLib.getDocument(pdfVal)
            loadingTask.promise.then((doc) => {
                pdfjsDoc = doc

                // console.info('- - readDoc', doc)
                pageCount.value = doc.numPages
                readPage(page.value, scale.value)
                isPdfLoaded.value = true

            },(error) => {
                isPdfLoaded.value = true

                if (error.name === 'PasswordException') {
                    console.info('The PDF is password-protected and no password was provided.')
                } else {
                    console.info('ERROR loading the PDF:', error)
                }
            })
        }
  
        const readPage = async (pageNum, pdfScale) => {

            if(pdfjsDoc) 
            {
                const page = await pdfjsDoc.getPage(pageNum)
                // console.info('page', page)
                const viewport = page.getViewport({ scale: pdfScale * 1.33333 })

                const containerDiv = document.getElementById('pdfSignBoxContainer')
                containerDiv.style.width = viewport.width + 'px'
                containerDiv.style.height = viewport.height + 'px'
                containerDiv.style.border = '0px solid orange'

                const canvas = document.getElementById('pdf-sign-canvas')
                const context = canvas.getContext('2d')
                const resolution = window.devicePixelRatio || 1

                // console.info('canvas: pdfBoxContainer', canvas)
                canvas.width = Math.floor(viewport.width) * resolution
                canvas.height = Math.floor(viewport.height) * resolution
                canvas.style.width = Math.floor(viewport.width) + 'px'
                canvas.style.height = Math.floor(viewport.height) + 'px'
                canvas.style.border = '0px solid blue'

                context.scale(resolution, resolution)

                const transform = [1, 0, 0, 1, 0, 0]

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    transform,
                    viewport,
                }
                page.render(renderContext)
                // const opList = await page.getOperatorList()

                const element = document.getElementById('pdf-sign-annotation-layer')
                element.width = Math.floor(viewport.width) * resolution
                element.height = Math.floor(viewport.height) * resolution
                element.style.width = Math.floor(viewport.width) + 'px'
                element.style.height = Math.floor(viewport.height) + 'px'
                // element.style.border = '2px solid red'
             
                const annots = await page.getAnnotations()
                for (const annot of annots) {
                    if(annot && annot.subtype === 'Link') {
                        renderAnnotationLink(annot, viewport, resolution, element)
                    } 
                }
            }
        }

        const renderAnnotationLink = (annotation, viewport, resolution, elAnnoLayer) => {
            const scale = viewport.scale

            const [x0, y0, x1, y1] = annotation.rect
            const yDiff = (y1 - y0) * scale
            const x = x0 * scale
            const y = Math.floor(viewport.height) - ( y0 * scale) - yDiff
            const width = (x1 - x0) * scale
            const height = (y1 - y0) * scale

            var lnk = window.document.createElement('a')
            lnk.href = annotation.url
            lnk.style.position = 'absolute'
            lnk.target = '_blank'
            lnk.style.left = `${x}px`
            lnk.style.top = `${y}px`
            lnk.style.width = `${width}px`
            lnk.style.height = `${height}px`
            // lnk.style.border = '1px solid red'
            // lnk.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'
            // lnk.style.pointerEvents = 'none'
            // lnk.style.innerText = annotation.title

            elAnnoLayer.appendChild(lnk)
        }

        const clearAnnotation = async () => {
            // console.info('clear annotation')
            const container = document.getElementById('pdf-sign-canvas')
            if (container !== null) {
                while (container.firstChild) {
                    container.removeChild(container.firstChild)
                }
            }

            const container2 = document.getElementById('pdf-sign-annotation-layer')
            if (container2 !== null) {
                while (container2.firstChild) {
                    container2.removeChild(container2.firstChild)
                }
            }
        }

        const zoomIn = () => {
            // console.info('zoomIn +')
            scale.value = parseFloat((scale.value + 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)

        }

        const zoomOut = async () => {
            // console.info('zoomOut -')
            scale.value = parseFloat((scale.value - 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const zoomDefault = async () => {
            // console.info('zoomDef')
            scale.value = 1
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const uplDrawSign = () => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = imgDrawS.value

            canvasDrawS.value.width = imageElement.naturalWidth
            canvasDrawS.value.height = imageElement.naturalHeight

            canvasDrawSContext.value = canvasDrawS.value.getContext('2d')
            canvasDrawSContext.value.filter = "brightness(150%)"
            canvasDrawSContext.value.filter = "contrast(150%)"
            
            canvasDrawSContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasDrawSContext.value.getImageData(0, 0, canvasDrawS.value.width, canvasDrawS.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0
                }
            }

            // Crop tranparent image

            canvasDrawSContext.value.putImageData(imageData, 0, 0)

            canvasDrawSContext.value = canvasDrawS.value.getContext('2d')
            const imageData2 = canvasDrawSContext.value.getImageData(0, 0, canvasDrawS.value.width, canvasDrawS.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasDrawS.value.width
                    y = ~~((i / 4) / canvasDrawS.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasDrawSContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasDrawSContext.value.canvas.width = trimWidth
            canvasDrawSContext.value.canvas.height = trimHeight
            canvasDrawSContext.value.putImageData(trimmed, 0, 0)


            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasDrawS.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')
                            vueCanvasDrawS.value.reset()

                        } else {
                            func.addLog('sign', 'uplDrawSign', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'uplDrawSign - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            })
        }

        const uplDrawInit = () => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = imgDrawI.value

            canvasDrawI.value.width = imageElement.naturalWidth
            canvasDrawI.value.height = imageElement.naturalHeight

            canvasDrawIContext.value = canvasDrawI.value.getContext('2d')
            canvasDrawIContext.value.filter = "brightness(150%)"
            canvasDrawIContext.value.filter = "contrast(150%)"
            canvasDrawIContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasDrawIContext.value.getImageData(0, 0, canvasDrawI.value.width, canvasDrawI.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasDrawIContext.value.putImageData(imageData, 0, 0)
            canvasDrawIContext.value = canvasDrawI.value.getContext('2d')
            const imageData2 = canvasDrawIContext.value.getImageData(0, 0, canvasDrawI.value.width, canvasDrawI.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasDrawI.value.width
                    y = ~~((i / 4) / canvasDrawI.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasDrawIContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasDrawIContext.value.canvas.width = trimWidth
            canvasDrawIContext.value.canvas.height = trimHeight
            canvasDrawIContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasDrawI.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('initial')
                            vueCanvasDrawI.value.reset()

                        } else {
                            func.addLog('sign', 'uplDrawInit', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('sign', 'uplDrawInit - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            })
        }

        const loadPdfToBase64 = async (url) => {
            /* ORIG 
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const pdfDoc = await PDFDocument.load(existingPdfBytes)
            const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true }) */

            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })

            /* if (docStatus.value === 'ALLSIGNED') {
                // PDF encrypted - load to base64 in differnt method
                try {
                    const result = await toBase64(blob)
                    return result

                } catch(error) {
                    console.info('- - Error loading encrypted pdf', error)
                    return
                }

            } else {
                // Not encrypted pdf
                const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
                const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true })
                return pdfVal
            } */

            // Handle 
            try {
                const result = await toBase64(blob)
                return result

            } catch(error) {
                // console.info('- - Error loading encrypted pdf', error)
                return
            }

        }

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
        })

        const handlePdfRendered = () => {
            isPdfLoaded.value = true
        }

        const convertSignTextToImg = () => {
            signContext.value.clearRect(0, 0, 300, 80)
            signContext.value.fillStyle = signFontColor.value
            signContext.value.font = signFontSize.value + " " + signFontFamily.value
            signContext.value.fillText(signText.value, 10, 50)

        }

        const uplSignText = () => {
            // Crop text to image
            const imageData2 = signContext.value.getImageData(0, 0, signCanvas.value.width, signCanvas.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % signCanvas.value.width
                    y = ~~((i / 4) / signCanvas.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = signContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            signContext.value.canvas.width = trimWidth
            signContext.value.canvas.height = trimHeight
            signContext.value.putImageData(trimmed, 0, 0)

            const canvas = signCanvas.value
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            canvas.toBlob((blob) => {
                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')
                            signText.value = ''
                            signCanvas.value.width = 300
                            signCanvas.value.height = 80

                        } else {
                            func.addLog('sign', 'uplSignText', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'uplSignText - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })
        }

        const convertInitTextToImg = () => {
            initContext.value.clearRect(0, 0, 300, 80)
            initContext.value.fillStyle = initFontColor.value
            initContext.value.font = initFontSize.value + " " + initFontFamily.value
            initContext.value.fillText(initText.value, 10, 50)

        }

        const uplInitText = () => {
            // Crop text to image
            const imageData2 = initContext.value.getImageData(0, 0, initCanvas.value.width, initCanvas.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % initCanvas.value.width
                    y = ~~((i / 4) / initCanvas.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = initContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            initContext.value.canvas.width = trimWidth
            initContext.value.canvas.height = trimHeight
            initContext.value.putImageData(trimmed, 0, 0)

            const canvas = initCanvas.value
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {
                        // console.info('Upload Res', JSON.stringify(res.data.data))
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('initial')
                            initText.value = ''
                            initCanvas.value.width = 300
                            initCanvas.value.height = 80

                        } else {
                            func.addLog('sign', 'uplInitText', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'uplInitText - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })
        }

        const downloadDoc = async () => {
            const existingPdfBytes = await fetch(doc.value.jFile.dlUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const downloadAuditLog = async () => {
            const existingPdfBytes = await fetch(doc.value.jFile.dlAuditRptUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name.substring(0, doc.value.jFile.name.length-4) + '_AuditLog.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const setDateReminder = () => {
        }

        const getSignature = (error, file) => {
            // console.info('- - - addfile: err', error)
            // console.info('getSignature: file', file)
            // console.info('getSignature: file', file.fileSize)
            // console.info('addfile: file.filename', file.filename)
            // console.info('addfile: file.fileSize', file.fileSize)
            // console.info('addfile: file.file', file.file)
            // console.info('addfile: file.source', file.source)
            // console.info('addfile: file.status', file.status)
            // console.info('addfile: file.transferId', file.transferId)
        }

        const addSignStart = async (file) => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasSContextSrc.value

            canvasS.value.width = imageElement.naturalWidth
            canvasS.value.height = imageElement.naturalHeight

            canvasSContext.value = canvasS.value.getContext('2d')
            canvasSContext.value.filter = "brightness(150%)"
            canvasSContext.value.filter = "contrast(150%)"
            canvasSContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasSContext.value.getImageData(0, 0, canvasS.value.width, canvasS.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasSContext.value.putImageData(imageData, 0, 0)
            canvasSContext.value = canvasS.value.getContext('2d')
            const imageData2 = canvasSContext.value.getImageData(0, 0, canvasS.value.width, canvasS.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasS.value.width
                    y = ~~((i / 4) / canvasS.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasSContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasSContext.value.canvas.width = trimWidth
            canvasSContext.value.canvas.height = trimHeight
            canvasSContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasS.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')

                            setTimeout(() => {
                                isUploadingSign.value = false
                            }, 500)
                            
                        } else {
                            func.addLog('sign', 'addSignStart', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'addSignStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            })

        }

        const addInitStart = async (file) => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasIContextSrc.value

            canvasI.value.width = imageElement.naturalWidth
            canvasI.value.height = imageElement.naturalHeight

            canvasIContext.value = canvasI.value.getContext('2d')
            canvasIContext.value.filter = "brightness(150%)"
            canvasIContext.value.filter = "contrast(150%)"
            canvasIContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasIContext.value.getImageData(0, 0, canvasI.value.width, canvasI.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]

                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0
                }
            }

            // Crop tranparent image
            canvasIContext.value.putImageData(imageData, 0, 0)
            canvasIContext.value = canvasI.value.getContext('2d')
            const imageData2 = canvasIContext.value.getImageData(0, 0, canvasI.value.width, canvasI.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasI.value.width
                    y = ~~((i / 4) / canvasI.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasIContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasIContext.value.canvas.width = trimWidth
            canvasIContext.value.canvas.height = trimHeight
            canvasIContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasI.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            // console.info('- - addInitStart SUCCESS')
                            getHistoryList('initial')

                            setTimeout(() => {
                                isUploadingInit.value = false
                            }, 500)

                        } else {
                            func.addLog('sign', 'addInitStart', res)
                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'addInitStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })
            
        }

        const addStaStart = async (file) => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasStContextSrc.value

            canvasSt.value.width = imageElement.naturalWidth
            canvasSt.value.height = imageElement.naturalHeight

            canvasStContext.value = canvasSt.value.getContext('2d')
            canvasStContext.value.filter = "brightness(150%)"
            canvasStContext.value.filter = "contrast(150%)"
            canvasStContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasStContext.value.getImageData(0, 0, canvasSt.value.width, canvasSt.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]

                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0
                }
            }

            // Crop tranparent image
            canvasStContext.value.putImageData(imageData, 0, 0)
            canvasStContext.value = canvasSt.value.getContext('2d')
            const imageData2 = canvasStContext.value.getImageData(0, 0, canvasSt.value.width, canvasSt.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasSt.value.width
                    y = ~~((i / 4) / canvasSt.value.width)
                
                    if (bound.top === null) {
                        bound.top = y
                    }
                    
                    if (bound.left === null) {
                        bound.left = x
                    } else if (x < bound.left) {
                        bound.left = x
                    }
                    
                    if (bound.right === null) {
                        bound.right = x
                    } else if (bound.right < x) {
                        bound.right = x
                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y
                    } else if (bound.bottom < y) {
                        bound.bottom = y
                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasStContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasStContext.value.canvas.width = trimWidth
            canvasStContext.value.canvas.height = trimHeight
            canvasStContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasSt.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/stamp', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('stamp')

                            setTimeout(() => {
                                isUploadingSta.value = false
                            }, 500)
                            
                        } else {
                            func.addLog('sign', 'addStaStart', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('sign', 'addStaStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })

        }

        const selectSignature = async (s) => {
            // console.info('selectSignature')
            // console.info('selectSignature', JSON.stringify(s))

            mdlSignature.value = s.signatureId
            // signatureSrc.value = s.imgBase64
            signatureSrc.value = s.signatureId
            signatureW.value = s.width
            signatureH.value = s.height
           
            randKey.value = Math.floor(Math.random() * 1000)
            // console.info('Final Signature:', signatureW.value, ' x ', signatureH.value)
        }

        const selectDataSignature = async (id, w, h) => {
            // console.info('selectSignature')
            // console.info('selectSignature', JSON.stringify(s))

            mdlSignature.value = id
            signatureSrc.value = id
            signatureW.value = w
            signatureH.value = h
           
            randKey.value = Math.floor(Math.random() * 1000)
            // console.info('Final Signature:', signatureW.value, ' x ', signatureH.value)
        }

        const selectInitial = async (i) => {
            mdlInitial.value = i.signatureId
            // initialSrc.value = i.imgBase64
            initialSrc.value = i.signatureId

            // For large screen only
            //pdfjs if (viewWidth.value > 500) {
                let origW = i.width
                let origH = i.height
                iniRatio.value = origW/origH

                // Both Width and Height exceed max
                if (origW > iniMaxW.value && origH > iniMaxH.value) {
                    // console.info('EXCEED BOTH')
                    initialW.value = iniMaxW.value
                    initialH.value = iniMaxW.value/iniRatio.value

                } else if (origW > iniMaxW.value) {
                    // console.info('EXCEED W')
                    initialH.value = origH
                    initialW.value = origH*iniRatio.value

                } else if (origH > iniMaxH.value) {
                    // console.info('EXCEED H')
                    initialW.value = origW
                    initialH.value = origW/iniRatio.value

                } else {
                    // console.info('X EXCEED')
                    // Both not exceed limit
                    initialW.value = origW
                    initialH.value = origH
                }

                // If resized initial still exceed
                if (initialH.value > iniMaxH.value) {
                    // console.info('**** H', initialH.value, ' > ', iniMaxH.value)
                    initialH.value = iniMaxH.value
                    initialW.value = initialH.value*iniRatio.value
                }

                if (initialW.value > iniMaxW.value) {
                    // console.info('**** W', initialW.value, ' > ', iniMaxW.value)
                    initialW.value = iniMaxW.value
                    initialH.value = initialW.value/iniRatio.value
                }

            // }
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const selectStamp = async (s) => {
            mdlStamp.value = s.signatureId
            stampSrc.value = s.signatureId
            // console.info('stamp', stampSrc.value);

            stampW.value = s.width
            stampH.value = s.height

            // For large screen only
            //pdfjs if (viewWidth.value > 500) {
                /* let origW = s.width
                let origH = s.height
                staRatio.value = origW/origH

                // Both Width and Height exceed max
                if (origW > staMaxW.value && origH > staMaxH.value) {
                    // console.info('EXCEED BOTH')
                    stampW.value = staMaxW.value
                    stampH.value = staMaxW.value/staRatio.value

                } else if (origW > staMaxW.value) {
                    // console.info('EXCEED W')
                    stampH.value = origH
                    stampW.value = origH*staRatio.value

                } else if (origH > staMaxH.value) {
                    // console.info('EXCEED H')
                    stampW.value = origW
                    stampH.value = origW/staRatio.value

                } else {
                    // console.info('X EXCEED')
                    // Both not exceed limit
                    stampW.value = origW
                    stampH.value = origH
                }

                // If resized signature still exceed
                if (stampH.value > staMaxH.value) {
                    // console.info('EXCEED H 2')
                    stampH.value = staMaxH.value
                    stampW.value = stampH.value*staRatio.value
                }

                if (stampW.value > staMaxW.value) {
                    // console.info('EXCEED W 2')
                    stampW.value = staMaxW.value
                    stampH.value = stampW.value/staRatio.value
                } */
            // }
            randKey.value = Math.floor(Math.random() * 1000)
        }
        
        const uplSignatureError = (error, file) => {
            console.info('uplSignatureError', error)

        }

        const uplInitialError = (error) => {
            console.info('uplInitialError', error)

        }

        const uplStampError = (error, file) => {
            console.info('uplStampError', error)

        }

        const onprocessSignature = (err, file) => {
            if (err === null) {
                pondS.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('signature')
                
                setTimeout(() => {
                    selectSignature(signatureHistList.value[0])
                }, 1000)
            }
        }

        const onprocessInitial = (err, file) => {
            // console.info('processfile: err', err)
            // console.info('processfile: file', file)
            if (err === null) {
                pondI.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('initial')
                
                setTimeout(() => {
                    selectInitial(initialHistList.value[0])
                }, 1000)
            }
        }

        const onprocessStamp = (err, file) => {
            if (err === null) {
                pondSt.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('stamp')
                
                setTimeout(() => {
                    selectStamp(stampHistList.value[0])
                }, 1000)
            }
        }

        const uplAttachmentError = (error, file) => {
            console.info('uplSignatureError', error)
        }

        const getAttachment = (error, file) => {
            // console.info('- - - addfile: err', error)
            // console.info('getSignature: file', file)
            // console.info('getSignature: file', file.fileSize)
            // console.info('addfile: file.filename', file.filename)
            // console.info('addfile: file.fileSize', file.fileSize)
            // console.info('addfile: file.file', file.file)
            // console.info('addfile: file.source', file.source)
            // console.info('addfile: file.status', file.status)
            // console.info('addfile: file.transferId', file.transferId)
        }

        const delSignature = (id, opt) => {

            let optStr = ''
            if (opt === 'initial') {
                optStr = 'Initial'

            } else if (opt === 'stamp') {
                optStr = 'Stamp'

            } else {
                optStr = 'Signature'

            }

            axios.delete( '/user/signature/' + id)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        if (opt === 'signature' && id === mdlSignature.value) {
                            mdlSignature.value = null
                            signatureSrc.value = null
                        }

                        if (opt === 'initial' && id === mdlInitial.value) {
                            mdlInitial.value = null
                            initialSrc.value = null
                        }

                        if (opt === 'stamp' && id === mdlStamp.value) {
                            mdlStamp.value = null
                            stampSrc.value = null
                        }

                        getHistoryList(opt)
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: optStr + " is removed."
                        })

                    } else {
                        func.addLog('sign', 'delSignature', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'delSignature - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const addRejectComment = () => {
            const p = { comment: rejectComment.value }

            axios.put( '/signon/signee/' + route.params.projectId + '/' + route.params.documentId + '/REJECTED', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is rejected."
                        })

                    } else {
                        func.addLog('sign', 'addRejectComment', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'addRejectComment - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const toggleToolbox = async () => {
            showToolbox.value = !showToolbox.value
            localStorage.leftSignDrawer = showToolbox.value
            localStorage.setItem('leftSignDrawer', showToolbox.value)
            // location.reload() // Need detect window size then recalculate signee object position

        }

        const getPosition = (id1, x1, y1) => {

        }

        const getResize = (t, id, w, h) => {
            if (t === 'fullSignature') {
                signatureW.value = w
                signatureH.value  = h
                // console.info('getResize: Signature', w, h, signatureW.value, signatureH.value)
            }

            if (t === 'initial') {
                initialW.value = w
                initialH.value  = h
            }

            if (t === 'stamp') {
                stampW.value = w
                stampH.value  = h
            }

        }

        /* pdfjs | const convObjToUi = (val) => {
            let tmpObj = val
            if (tmpObj.length === 0) {
                return tmpObj

            } else {
                tmpObj.forEach((item, idx) => {
                    tmpObj[idx].position.x = item.position.x * uiRatio.value
                    tmpObj[idx].position.y = item.position.y * uiRatio.value

                })

                return tmpObj
            } 
        } */

        const newBox = computed(() => {
            return DragBox
        })

        const updPageNum = (page1) => {
            goToPage.value = page1
            getDocBoxesToPgBoxes()
        }

        const updObjSign = (id, type, val) => {
            if (type === 'name') mdlName.value = val
            if (type === 'email') mdlEmail.value = val
            if (type === 'idPassport') mdlIdPassport.value = val
            if (type === 'textbox') mdlTextbox.value[id] = val
            if (type === 'textarea') mdlTextarea.value[id] = val
            if (type === 'checkbox') mdlCheckbox.value[id] = val

            // Change textbox, textarea and checkbox value
            if (type === 'textbox' || type === 'textarea' || type === 'checkbox') {
                objSign.value.forEach((item, idx) => {
                    if (item.id === id) {
                        objSign.value[idx].value = val //replace value
                    }
                })

            }
        }

        const confirmSign = async () => {
            if (docBoxes.value.length > 0) {
                isPdfLoaded.value = false
                let curDate = new Date()

                docBoxes.value.forEach((item, idx) => {

                    // fullSignature
                    if (item.type === 'fullSignature') {
                        // console.info('confirmSign 1', signatureW.value, signatureH.value)
                        objSign.value.push({id: item.id, value: mdlSignature.value, type: item.type, width: signatureW.value, height: signatureH.value})
                    }

                    // initial
                    if (item.type === 'initial') {
                        objSign.value.push({id: item.id, value: mdlInitial.value, type: item.type, width: initialW.value, height: initialH.value})
                    }

                    if (item.type === 'stamp') {
                        // console.info('stamp--- ', item.id, stampW.value, stampH.value)
                        objSign.value.push({id: item.id, value: mdlStamp.value, type: item.type, width: stampW.value, height: stampH.value})
                    }

                    if (item.type === 'comboSignature') {
                        const tmpObj = {id: item.id, signatureId: mdlSignature.value, stampId: '', type: item.type, width: item.width, height: item.height, combo: {}}

                        if ( item.comboStructure === 'signstaoverlap') {
                            tmpObj.stampId = mdlStamp.value
                        } 

                        if (item.combo) {
                            console.info('- - combo', JSON.stringify(item.combo))
                            if (item.combo.includes('cFullLegalName')) {
                                tmpObj.combo.cFullLegalName = comboData.value.fullLegalName
                            }
                            if (item.combo.includes('cDesignation')) {
                                tmpObj.combo.cDesignation = comboData.value.designation
                            }
                            if (item.combo.includes('cOrganization')) {
                                tmpObj.combo.cOrganization = comboData.value.organization
                            }
                            if (item.combo.includes('cNric')) {
                                tmpObj.combo.cNric = comboData.value.nric
                            }
                            if (item.combo.includes('cPassport')) {
                                tmpObj.combo.cPassport = comboData.value.passport
                            }
                            if (item.combo.includes('cDateSigned')) {
                                tmpObj.combo.cDateSigned = new Date()
                            }
                        }

                        objSign.value.push(tmpObj)
                    }

                    // Handle Date Signed
                    if (item.type === 'dateSigned') {
                        objSign.value.push({id: item.id, value: curDate, type: item.type})
                    }
                    
                    if (item.type === 'name' ) {
                       objSign.value.push({id: item.id, value: mdlName.value, type: item.type})
                    }

                    if (item.type === 'email' ) {
                       objSign.value.push({id: item.id, value: mdlEmail.value, type: item.type})
                    }

                    if (item.type === 'idPassport' ) {
                       objSign.value.push({id: item.id, value: mdlIdPassport.value, type: item.type})
                    }
                }) //docBoxes

                // finalObjSign do not have type param
                let finalObjSign = []
                objSign.value.forEach((item) => {
                    if (item.type === 'fullSignature' || item.type === 'initial' || item.type === 'stamp') {
                        // Convert width and height based on ratio
                        //pdfjs | finalObjSign.push({id: item.id, value: item.value, width: item.width/uiRatio.value, height: item.height/uiRatio.value})
                        finalObjSign.push({id: item.id, value: item.value, width: item.width, height: item.height})

                    } else if (item.type === 'comboSignature') {
                        //pdfjs | finalObjSign.push({id: item.id, value: item.value, width: item.width/uiRatio.value, height: item.height/uiRatio.value, combo: item.combo})
                        finalObjSign.push({id: item.id, signatureId: item.signatureId, stampId: item.stampId, width: item.width, height: item.height, combo: item.combo})

                    } else {
                        finalObjSign.push({id: item.id, value: item.value})
                    }
                })

                const p = {
                    jObject: finalObjSign,
                    comment: comment.value
                }

                console.info('confirmSign - p', JSON.stringify(p))

                axios.put( '/signon/signee/' + route.params.projectId + '/' + route.params.documentId, p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            iniLoad()
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Document is signed."
                            })

                        } else {
                            func.addLog('sign', 'confirmSign', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'not_activated') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Sorry, unable to sign. Please activate your account to confirm your email before signing.'
                                    })

                                } else if (res.data.message === 'signature_not_found') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Signature not found.'
                                    })

                                } else if (res.data.message === 'initial_not_found') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Initial not found.'
                                    })

                                } else if (res.data.message === 'empty_name') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Name not found.'
                                    })

                                } else if (res.data.message === 'empty_id') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'ID/Passport not found.'
                                    })

                                } else if (res.data.message === 'empty_object') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Sign object not found.'
                                    })
                                    
                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })
                                }
                            }
                        }

                        isPdfLoaded.value = true
                    })
                    .catch((error) => {
                        isPdfLoaded.value = true
                         func.addLog('sign', 'confirmSign - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })
            }
        }

        const getDoc = async () => {
            isPdfLoading.value = true
            mdlNameOpt.value = []
            mdlIdPassportOpt.value = []
            docSignee.value = []

            try {
                const p = {
                    orderBy: [
                        {
                            field: 'jAuditLog',
                            order: 'desc'
                        }
                    ]
                }

                const res = await axios.post('/signon/signee/document/' + route.params.documentId, p)

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    pdfWidth.value = res.data.data.jFile.pageWidth
                    pdfHeight.value = res.data.data.jFile.pageHeight
                    pageCount.value = res.data.data.jFile.totalPage
                    docWidth.value = res.data.data.jFile.pageWidth

                    /* pdfjs if (uiRatio.value === 0 || uiRatio.value === Infinity ) {
                        setUiRatio()
                    } */

                    // console.info('getDoc', JSON.stringify(res.data.data))
                    // console.info('getDoc - jSignee', JSON.stringify(res.data.data.jSignee))
                    // console.info('getDoc - doc', JSON.stringify(res.data.data))
                    // console.info('getDoc - jRemoval', JSON.stringify(res.data.data.jRemoval))

                    doc.value = res.data.data
                    isEncrypted.value = res.data.data.jFile.isEncrypted
                    docName.value = res.data.data.jFile.name.replace('.pdf', '')
                    docStatus.value = res.data.data.status
                    docShred.value = res.data.data.shred

                    if (res.data.data.jRemoval) {
                        docShredApproval.value = res.data.data.jRemoval.status
                        docShredApprovalDate.value = res.data.data.jRemoval.dateAction
                    }
 
                    showObj.value = res.data.data.jUSignee.signingStatus === 'PENDINGSIGN' ? true : false
                    isDocSigned.value = (res.data.data.jUSignee.signingStatus === 'PENDING' || res.data.data.jUSignee.signingStatus === 'PENDINGSIGN' || res.data.data.shred === 'PENDING')? false : true
                    url.value = res.data.data.jFile.viewUrl
                    // console.info('getDoc - url', url.value)

                    // console.info('getDoc - showObj', res.data.data.jUSignee.signingStatus)
                     
                    if (doc.value.jSignee) {
                        // console.info('getDoc - jSignee', JSON.stringify(doc.value.jSignee))
                        // Get OWNER & EDITOR first
                        doc.value.jSignee.forEach((item, idx) => {
                            if (doc.value.jSignee.privilege === 'OWNER' || doc.value.jSignee.privilege === 'EDITOR' ) {
                                docSignee.value.push(item)
                            }

                            // For auto-fill fields
                            if (item.signeeId === getUserId.value) {
                               
                               if (item.displayName !== '' && item.displayName !== null && mdlNameOpt.value.includes(item.displayName) === false) {
                                    mdlNameOpt.value.push(item.displayName)

                                }

                                if (item.fullName !== '' && item.fullName !== null && mdlNameOpt.value.includes(item.fullName) === false) {
                                    mdlNameOpt.value.push(item.fullName)

                                }

                                mdlName.value = (item.fullName === '' ||  item.fullName === null || item.fullName === undefined) ? item.displayName : item.fullName
                                mdlEmail.value = item.signeeId
                                // Pending from Backend mdlIdPassport.value = res.data.data.signee.id
                            }

                            if(item.signingStatus === 'REJECTED') {
                                arrRejectedBySignee.value.push({ signeeName: item.signeeName, signingStatus: item.signingStatus, signeeId: item.signeeId })
                            }
                        })
                    }
                    
                    if (doc.value.jSignee) {
                        // Get Others (Neither OWNER nor EDITOR)
                        doc.value.jSignee.forEach((item, idx) => {
                            if (doc.value.jSignee.privilege !== 'OWNER' && doc.value.jSignee.privilege !== 'EDITOR' ) {
                                docSignee.value.push(item)
                            }

                            // For auto-fill fields
                            if (item.signeeId === getUserId.value) {
                                if (item.displayName !== '' && item.displayName !== null && mdlNameOpt.value.includes(item.displayName) === false) {
                                    mdlNameOpt.value.push(item.displayName)

                                }

                                if (item.fullName !== '' && item.fullName !== null && mdlNameOpt.value.includes(item.fullName) === false) {
                                    mdlNameOpt.value.push(item.fullName)

                                }

                                mdlName.value = (item.fullName === '' || item.fullName === null || item.fullName === undefined) ? item.displayName : item.fullName
                                mdlEmail.value = item.signeeId
                                // Pending from Backend mdlIdPassport.value = res.data.data.signee.id
                            } 
                        })
                    }

                    // Show object only if the signee not sign doc yet
                    if (showObj.value === true) {
                        // docBoxes.value = convObjToUi(doc.value.jUSignee.jObject)
                        docBoxes.value = doc.value.jUSignee.jObject
                        // console.info('---- getDoc docBoxes', JSON.stringify(docBoxes.value))
                        await getDocBoxesToPgBoxes()
                        await getObjectList(true)
                    } 
                    isPdfLoading.value = false

                } else {
                    isPdfLoading.value = false
                    func.addLog('sign', 'getDoc', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }

            } catch (error) {
                isPdfLoading.value = false
                func.addLog('sign', 'getDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            }
            
        }

        const getProj = async () => {
            try {
                const p = {
                    orderBy: [
                        {
                            field: 'jAuditLog',
                            order: 'desc'
                        }
                    ]
                }

                const res = await axios.post('/signon/signee/' + route.params.projectId, p)

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    totalDoc.value = res.data.data.total
                    projPrivilege.value = res.data.data.privilege

                } else {
                    func.addLog('sign', 'getProj', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }

                }

            } catch (error) {
                func.addLog('sign', 'getProj - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            }
        }

        const getObjectList = async (setObjList) => {
            let tmpList = []
            let objSignTmp = objSign.value // Get back previous value entered in mdl

            objSign.value = []
            
            docBoxes.value.forEach((item, idx) => {
                // Insert checkbox, textbox and textarea into objSign (allow duplicate as each field different value)
                if (item.type === 'checkbox') {
                    let match = false
                    objSignTmp.forEach((oItem, oIdx) => {
                        if (oItem.id === item.id) {
                            match = true
                            objSign.value.push({id: item.id, value: oItem.value, type: item.type})

                        }
                    })

                    if (!match) {
                        objSign.value.push({id: item.id, value: false, type: item.type})

                    }
                }

                if (item.type === 'textbox' || item.type === 'textarea') {
                    let match = false
                    objSignTmp.forEach((oItem, oIdx) => {
                        if (oItem.id === item.id) {
                            match = true
                            objSign.value.push({id: item.id, value: oItem.value, type: item.type})
                        }
                    })

                    if (!match) {
                        objSign.value.push({id: item.id, value: '', type: item.type})
                    }
                }

                if (!tmpList.includes(item.type)) {
                    tmpList.push(item.type)
                }
            })

            if (setObjList) {
                tmpList.forEach((obj, objIdx) => {
                    objList.value.push({ type: obj, pages: []})
                    docBoxes.value.forEach((item, idx) => {

                        if (obj === item.type) {
                            if(!objList.value[objIdx].pages.includes(item.position.page)) {
                                objList.value[objIdx].pages.push(item.position.page)

                            }
                        }
                    })
                })
            }
        }

        const getHistoryList = (listOpt) => {
            axios.get( '/user/signature/list/' + listOpt)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        if (listOpt === 'initial') {
                            initialHistList.value = res.data.data
                            
                            // Select initial by default
                            if (initialHistList.value.length > 0 && mdlInitial.value === null) {
                                selectInitial(initialHistList.value[0])
                            }

                        } else if (listOpt === 'stamp') {
                            stampHistList.value = res.data.data
                            
                            // Select initial by default
                            if (stampHistList.value.length > 0 && mdlStamp.value === null) {
                                selectStamp(stampHistList.value[0])
                            }

                        } else {
                            signatureHistList.value = res.data.data

                            // Select signature by default
                            if (signatureHistList.value.length > 0 && mdlSignature.value === null) {
                                selectSignature(signatureHistList.value[0])
                            }

                        }
                    } else {
                        func.addLog('sign', 'getHistoryList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        } 
                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'getHistoryList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getDocBoxesToPgBoxes = async () => {
            // Get pgBoxes from docBoxes
            pgBoxes.value = []
            docBoxes.value.forEach((item, idx) => {
                if (item.isMandatory === true || item.isMandatory === 'true') {
                    totalCompulsoryField.value++
                }

                if (item.position.page === goToPage.value) {
                    pgBoxes.value.push(item)
                }

                if (item.type === 'comboSignature' && item.comboStructure === 'sign' && previewCombo.value.length === 0) {
                    previewCombo.value = item.combo
                    previewComboObj.value = item
                }
            })
        }

        const iniMdl = () => {
            mdlTotalStep.value = 0

            docBoxes.value.forEach((item) => {

                if (item.type === 'fullSignature' || item.type === 'comboSignature') {
                    hasMdlSignature.value = true
                }

                if (item.type === 'initial') {
                    hasMdlInitial.value = true
                }

                if (item.type === 'stamp' || (item.type === 'comboSignature' && item.comboStructure === 'signstaoverlap')) {
                    hasMdlStamp.value = true
                }

                if (item.type === 'comboSignature') {
                    // console.info('- - item', item.comboStructure, JSON.stringify(item))
                    hasMdlCombo.value = true

                    // Detect combo object and value for confirmation modalbox
                    if (item.combo && item.combo.includes('cFullLegalName')) {
                        hasComboFullLegalName.value = true
                    }
                    if (item.combo && item.combo.includes('cDesignation')) {
                        hasComboDesignation.value = true
                    }
                    if (item.combo && item.combo.includes('cOrganization')) {
                        hasComboOrganization.value = true
                    }
                    if (item.combo && item.combo.includes('cNric')) {
                        hasComboNric.value = true
                    }
                    if (item.combo && item.combo.includes('cPassport')) {
                        hasComboPassport.value = true
                    }
                    if (item.combo && item.combo.includes('cDateSigned')) {
                        hasComboDateSigned.value = true
                    }

                    if (item.comboStructure === 'signstaoverlap') {
                        hasComboStructureOverlap.value = true
                    }

                    if (item.comboStructure === 'sign') {
                        hasComboStructureSign.value = true
                    }
                }

                if (item.type === 'dateSigned' || item.type === 'name' || item.type === 'email' || item.type === 'idPassport') {
                    hasMdlAutoFill.value = true
                    getModalField('mdlAutoFill')
                }

                if (item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') {
                    hasMdlStandard.value = true
                    getModalField('mdlStandard')
                }
            })

            if (hasMdlSignature.value) mdlTotalStep.value ++
            if (hasMdlInitial.value) mdlTotalStep.value ++
            if (hasMdlStamp.value) mdlTotalStep.value ++
            if (hasMdlAutoFill.value) mdlTotalStep.value ++
            if (hasMdlStandard.value) mdlTotalStep.value ++

            if (hasMdlSignature.value) {
                mdlFirstStep.value = 'mdlSignature'
                if (hasMdlInitial.value) {
                    mdlNextStep.value = 'mdlInitial'

                } else if (hasMdlStamp.value) {
                    mdlNextStep.value = 'mdlStamp'

                } else if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                }

            } else if (hasMdlInitial.value) {
                mdlFirstStep.value = 'mdlInitial'
                if (hasMdlStamp.value) {
                    mdlNextStep.value = 'mdlStamp'

                } else if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                }

            } else if (hasMdlStamp.value) {
                mdlFirstStep.value = 'mdlStamp'
                if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                }

            } else if (hasMdlAutoFill.value) {
                mdlFirstStep.value = 'mdlAutoFill'
                if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                }

            } else if (hasMdlStandard.value) {
                mdlFirstStep.value = 'mdlStandard'

                if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                } else {
                    mdlNextStep.value = null
                }
            } else if (hasMdlCombo.value) {

                mdlFirstStep.value = 'mdlCombo'
                mdlNextStep.value = null
            }
        }

        const getModalField = (mdlId) => {
            let arrDuplicate = []

            if (mdlId === 'mdlAutoFill') {
                mdlFieldAuto.value = []

            } else {
                mdlFieldStd.value = []

            }

            docBoxes.value.forEach((item, index) => {
                if (mdlId === 'mdlAutoFill') {
                    // Unique field only
                    if (item.type === 'dateSigned' || item.type === 'email' || item.type === 'name' || item.type === 'idPassport') {
                        if (arrDuplicate.includes(item.type) === false) {
                            arrDuplicate.push(item.type)
                            mdlFieldAuto.value.push(item)

                        }
                    }
                }

                if (mdlId === 'mdlStandard') {
                    // All standard fields (included duplicate)
                    if (item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') {
                        mdlFieldStd.value.push(item)

                    }
                }

            })
        }

        const getCurDateTime = (format) => {
            // Either YYYY-MM-DD or DD-MMM-YYYY
            const curDate = new Date()
            const strCurDate = String(curDate)
            const curMonth = ref(null)
            curMonth.value = curDate.getMonth() + 1

            if (format === 'DD-MMM-YYYY') {
                // console.info('curMonth ' +typeof curMonth.value, curMonth.value)
                if (curMonth.value === 1) curMonth.value = 'JAN'
                if (curMonth.value === 2) curMonth.value = 'FEB'
                if (curMonth.value === 3) curMonth.value = 'MAR'
                if (curMonth.value === 4) curMonth.value = 'APR'
                if (curMonth.value === 5) curMonth.value = 'MAY'
                if (curMonth.value === 6) curMonth.value = 'JUN'
                if (curMonth.value === 7) curMonth.value = 'JUL'
                if (curMonth.value === 8) curMonth.value = 'AUG'
                if (curMonth.value === 9) curMonth.value = 'SEP'
                if (curMonth.value === 10) curMonth.value = 'OCT'
                if (curMonth.value === 11) curMonth.value = 'NOV'
                if (curMonth.value === 12) curMonth.value = 'DEC'

                const gmt = ref(null)
                const tmpDate = ref([]) 
                tmpDate.value = strCurDate.split('GMT')
                tmpDate.value = tmpDate.value[1].split(' (')
                gmt.value = tmpDate.value[0]
                
                return curDate.getDate() + '-' + curMonth.value + '-' + curDate.getFullYear() + ' (' + gmt.value + ')'
            
            } else {
                return curDate.getFullYear() + '-' + (curMonth.value < 10 ? '0' + curMonth.value : curMonth.value) + '-' + curDate.getDate() + ' (' + gmt.value + ')'

            }
        }

        const iniLoad = async () => {
            // console.info('---- func: iniLoad')
            if (localStorage.getItem('leftSignDrawer') === undefined || localStorage.getItem('leftSignDrawer') === null) {
                // Default - show toolbar for user to close
                showToolbox.value = true
                localStorage.setItem('leftSignDrawer', true)

            } else {
                showToolbox.value = localStorage.getItem('leftSignDrawer') === 'true'

            }

            await getDoc()
            await getProj()
            await iniMdl()
            await getProfile()  // for combo data, autofill: idPassport purpose

            if (hasMdlCombo.value === true) {
                await getComboData()
            }
            showUIBox.value = true
            
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const navigatePage = (opt) => {
            if (opt === 'prev') {
                page.value = page.value - 1
                goToPage.value = page.value

            } else if (opt === 'next') {
                page.value++
                goToPage.value = page.value

            } else if (opt === 'first') {
                page.value = 1
                goToPage.value = page.value

            } else if (opt === 'last') {
                page.value = pageCount.value
                goToPage.value = page.value     

            } else if (opt !== '' && opt > 0) {
                page.value = opt * 1
                goToPage.value = opt * 1  

            } else {
                goToPage.value = goToPage.value * 1
                if (goToPage.value > 0 && goToPage.value <= pageCount.value) {
                    page.value = goToPage.value

                } else { 
                    page.value = 1
                    goToPage.value = page.value
                }
            }
            randKey.value = Math.floor(Math.random() * 1000)
        }

        /* pdfjs const getWindowSize = () => {
            // console.info('- - - setWindowResize - SET viewWidth', pdfContainer.value.clientWidth, pdfContainer.value.clientHeight)
            if (pdfContainer.value !== null && pdfContainer.value !== undefined) {
                viewWidth.value = pdfContainer.value.clientWidth
                viewHeight.value = pdfContainer.value.clientHeight

                setUiRatio()
            }
        } 
            
        const setUiRatio = async () => {
            if (pdfWidth.value > 0) {
                uiRatioPrev.value = uiRatio.value // set current ratio to previous ratio before change current ratio
                uiRatio.value = viewWidth.value/pdfWidth.value
                
                reCalcPosition()
                randKey.value = Math.floor(Math.random() * 1000)
            }
        } 

        const reCalcPosition = async () => {
            let tmpX = 0
            let tmpY = 0
            
            docBoxes.value.forEach((item, itemIdx) => {
                tmpX = docBoxes.value[itemIdx].position.x / uiRatioPrev.value
                tmpY = docBoxes.value[itemIdx].position.y / uiRatioPrev.value

                docBoxes.value[itemIdx].position.x = tmpX * uiRatio.value
                docBoxes.value[itemIdx].position.y = tmpY * uiRatio.value
            })
            await getDocBoxesToPgBoxes()
        } 
        */
        const getComboData = () => {
            axios.get('/user/profile')
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        comboData.value.fullLegalName = res.data.data.fullName
                        comboData.value.nric = res.data.data.jIdentity.nric
                        comboData.value.passport = res.data.data.jIdentity.passport
                        comboData.value.designation = res.data.data.jEntity.designation
                        comboData.value.organization = res.data.data.jEntity.entityName

                        if (hasComboFullLegalName.value === true && (res.data.data.fullName === '' || res.data.data.fullName === null || res.data.data.fullName === undefined)) {
                            validCombo.value = false
                        }

                        if (hasComboDesignation.value === true && (res.data.data.jEntity.designation === '' || res.data.data.jEntity.designation === null || res.data.data.jEntity.designation === undefined)) {
                            validCombo.value = false
                        }

                        if (hasComboOrganization.value === true && (res.data.data.jEntity.entityName === '' || res.data.data.jEntity.entityName === null || res.data.data.jEntity.entityName === undefined)) {
                            validCombo.value = false
                        }

                        if (hasComboNric.value === true && (res.data.data.jIdentity.nric === '' || res.data.data.jIdentity.nric === null || res.data.data.jIdentity.nric === undefined)) {
                            validCombo.value = false
                        }

                        if (hasComboPassport.value === true && (res.data.data.jIdentity.passport === '' || res.data.data.jIdentity.passport === null || res.data.data.jIdentity.passport === undefined)) {
                            validCombo.value = false
                        }

                        previewCombo.value.forEach((item1, idx1) => {
                            if (item1 === 'cFullLegalName') {
                                previewComboData.value.push(res.data.data.fullName)
                            }
                            if (item1 === 'cNric') {
                                previewComboData.value.push(res.data.data.jIdentity.nric)
                            }
                            if (item1 === 'cPassport') {
                                previewComboData.value.push(res.data.data.jIdentity.passport)
                            }
                            if (item1 === 'cDesignation') {
                                previewComboData.value.push(res.data.data.jEntity.designation)
                            }
                            if (item1 === 'cOrganization') {
                                previewComboData.value.push(res.data.data.jEntity.entityName)
                            }
                            if (item1 === 'cDateSigned') {
                                previewComboData.value.push(func.convDateTimeFormat(new Date(), 'datetext'))
                            }
                        })
                        // previewComboData.value.push('aaaaa testing 1 aaaa testing 2 aaaaa testing 3 aaaaa testing')

                    } else {
                        func.addLog('sign', 'getComboData', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'getComboData - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getProfile = () => {
            axios.get('/user/profile')
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        if (hasMdlCombo.value === true) {
                            comboData.value.fullLegalName = res.data.data.fullName
                            comboData.value.nric = res.data.data.jIdentity.nric
                            comboData.value.passport = res.data.data.jIdentity.passport
                            comboData.value.designation = res.data.data.jEntity.designation
                            comboData.value.organization = res.data.data.jEntity.entityName
                        }

                        if (res.data.data.jIdentity.nric !== '' && res.data.data.jIdentity.nric !== null && res.data.data.jIdentity.nric !== undefined) {
                            mdlIdPassport.value = res.data.data.jIdentity.nric
                            mdlIdPassportOpt.value.push(res.data.data.jIdentity.nric)
                            
                        } else if (res.data.data.jIdentity.passport !== '' && res.data.data.jIdentity.passport !== null && res.data.data.jIdentity.passport !== undefined) {
                            mdlIdPassport.value = res.data.data.jIdentity.passport
                        }

                        if (res.data.data.jIdentity.passport !== '' && res.data.data.jIdentity.passport !== null && res.data.data.jIdentity.passport !== undefined) {
                            mdlIdPassportOpt.value.push(res.data.data.jIdentity.passport)
                        }

                    } else {
                        func.addLog('sign', 'getProfile', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'getProfile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const iniScale = async () => {
            // console.info('iniScale', 'browserWidth:', browserWidth.value, docWidth.value)

            if (browserWidth.value <= 380) {
            // cater for mobile phone
            scale.value = 0.4

            } else if (browserWidth.value <= 428) {
            // cater for mobile phone
            scale.value = 0.5

            } else if (browserWidth.value <= 820) {
            // cater for tablet
            scale.value = 0.7

            } else {
                if (docWidth.value) {
                    let pdfWidth = docWidth.value * 1.33333.toFixed(5) * 1 //convert docWidth from pt to px
                    let pdfBox = browserWidth.value - 255 // deduct toolbar

                    let newScale = scale.value
                    let newPdfWidth = pdfWidth

                    for(var tmpScale = 1; tmpScale <= 2; tmpScale+=0.1) {
                        tmpScale = tmpScale.toFixed(1) * 1
                        newPdfWidth = pdfWidth * tmpScale

                        if (newPdfWidth <= pdfBox) {
                            newScale = tmpScale
                        }
                    }

                    scale.value = newScale
                    // console.info('final scale: ' + newScale, 'pdfWidth', pdfWidth)

                } 
            }
            randKey.value = Math.floor(Math.random() * 1000)
        }


        onMounted(async () => {
            /* pdfjs | window.addEventListener('resize', getWindowSize)
            getWindowSize() */

            browserWidth.value = window.innerWidth
            dateReminder.value = func.convDateTimeFormat(new Date(), 'datetime')

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }
            iniLoad()

            
            setTimeout(() => {
                iniScale()
            }, 800)

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            const fSign = document.querySelector('.signature')
            fSign.addEventListener('change', (event) => {

                isUploadingSign.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasSContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            // Detect drop signature
            const fSign2 = document.querySelector('.signature')
            fSign2.addEventListener('drop', (event) => {

                isUploadingSign.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasSContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            const fInit = document.querySelector('.initial')
            fInit.addEventListener('change', (event) => {
   
                isUploadingInit.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasIContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            // Detect drop initial
            const fInit2 = document.querySelector('.initial')
            fInit2.addEventListener('drop', (event) => {

                isUploadingInit.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasIContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            const fSta = document.querySelector('.stamp')
            fSta.addEventListener('change', (event) => {

                isUploadingSta.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasStContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            // Detect drop stamp
            const fSta2 = document.querySelector('.stamp')
            fSta2.addEventListener('drop', (event) => {

                isUploadingSta.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasStContextSrc.value = srcData
                    }
                    fileReader.readAsDataURL(imageFile)
                }
            })

            curDateTime.value = getCurDateTime('DD-MMM-YYYY')
            signContext.value = signCanvas.value.getContext('2d')
            initContext.value = initCanvas.value.getContext('2d')

            getHistoryList('initial')
            getHistoryList('signature')
            getHistoryList('stamp')

            let objMdl1 = document.querySelector('#mdlSignature')
            objMdl1.addEventListener("shown.bs.modal", function (event) {

                mdlCurStep.value = 1
                getObjectList(false) // Everytime first click modal

                if (hasMdlInitial.value) {
                    mdlNextStep.value = 'mdlInitial'

                } else if (hasMdlStamp.value) {
                    mdlNextStep.value = 'mdlStamp'

                } else if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'

                } else {
                    mdlNextStep.value = null

                }
            })

            let objMdl2 = document.querySelector('#mdlInitial')
            objMdl2.addEventListener("shown.bs.modal", function (event) {

                if (hasMdlSignature.value) {
                    mdlCurStep.value ++

                } else {
                    mdlCurStep.value = 1

                }

                if (hasMdlStamp.value) {
                    mdlNextStep.value = 'mdlStamp'

                } else if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'

                } else {
                    mdlNextStep.value = null

                }
            })

            let objMdl3 = document.querySelector('#mdlStamp')
            objMdl3.addEventListener("shown.bs.modal", function (event) {

                if (hasMdlSignature.value === false && hasMdlInitial.value === false) {
                    mdlCurStep.value = 1

                } else {
                    mdlCurStep.value ++

                }

                if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'

                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'
                
                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'

                } else { 
                    mdlNextStep.value = null

                }
            })
            
            let objMdl4 = document.querySelector('#mdlAutoFill')
            objMdl4.addEventListener("shown.bs.modal", function (event) {

                if(hasMdlSignature.value === false && hasMdlInitial.value === false && hasMdlStamp.value === false) {
                    mdlCurStep.value = 1

                } else {
                    mdlCurStep.value ++

                }
                
                if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'

                } else if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'

                } else { 
                    mdlNextStep.value = null

                }
            })

            let objMdl5 = document.querySelector('#mdlStandard')
            objMdl5.addEventListener("shown.bs.modal", function (event) {

                if (hasMdlSignature.value === false && hasMdlInitial.value === false && hasMdlAutoFill.value === false) {
                    mdlCurStep.value = 1

                } else {
                    mdlCurStep.value ++

                }

                if (hasMdlCombo.value) {
                    mdlNextStep.value = 'mdlCombo'
                } else {
                    mdlNextStep.value = null
                }
            })

            let objMdl6 = document.querySelector('#mdlCombo')
            objMdl6.addEventListener("shown.bs.modal", function (event) {

                /* if (hasMdlSignature.value === false && hasMdlInitial.value === false && hasMdlAutoFill.value === false) {
                    mdlCurStep.value = 1

                } else {
                    mdlCurStep.value ++

                } */
                
                mdlNextStep.value = null
            })

        })

        return {
            showDebug, axios, func, route, router, store, getUserId, contentClass, alert, closeAlert, newBox, signeeSelectedClass, showUIBox, isPdfLoaded, isSignOn, isSolidBg, toggleOthers, 
            hasMdlSignature, hasMdlInitial, hasMdlStamp, hasMdlCombo, hasMdlAutoFill, hasMdlStandard, 
            mdlFirstStep, mdlNextStep, mdlCurStep, mdlTotalStep, mdlTextbox, mdlTextarea, mdlCheckbox, mdlName, mdlEmail, mdlIdPassport, mdlDateSigned, 
            doc, docName, docStatus, docShred, docShredApproval, docShredApprovalDate, isDocSigned, showObj, url, urlEncode, showToolbox, toolboxBtnIcon, toggleToolbox, docBoxes, pgBoxes, signeeId, getCurDateTime,
            updPageNum, getDoc, objList, objSign, updObjSign, confirmSign, totalCompulsoryField,
            signatureHistList, initialHistList, stampHistList, 
            delSignature, onprocessInitial, uplInitialError, onprocessSignature, uplSignatureError, onprocessStamp, uplStampError,
            mdlSignature, mdlInitial, mdlStamp, mdlFieldAuto, mdlFieldStd, getSignature, mdlNameOpt, mdlIdPassportOpt, curDateTime, 
            getResize, signatureW, signatureH, initialW, initialH, stampW, stampH, signatureSrc, initialSrc, stampSrc, 
            selectSignature, selectInitial, selectStamp, signRatio, iniRatio, staRatio, totalDoc, showMoreSignee, docSignee, isAgree,
            signCanvas, signContext, signText, signFontFamily, signFontSize, signFontColor, signImg, uplSignText,
            initCanvas, initContext, initText, initFontFamily, initFontSize, initFontColor, initImg, uplInitText,
            pondS, canvasS, canvasSContext, canvasSContextSrc, mySignatures, addSignStart,
            pondI, canvasI, canvasIContext, canvasIContextSrc, myInitials, addInitStart,
            pondSt, canvasSt, canvasStContext, canvasStContextSrc, myStamps, addStaStart,
            isUploadingSign, isUploadingInit, isUploadingSta, signMaxW, signMaxH, iniMaxW, iniMaxH, staMaxW, staMaxH,
            pdfContainer, pdfSign, pdfWidth, pdfHeight, /* uiRatio, uiRatioPrev, */ viewWidth, viewHeight, isPdfLoading, page, pageCount, goToPage, 
            randKey, rejectComment, projPrivilege, addRejectComment, navigatePage, 
            vueCanvasDrawS, imgDrawS, imgDrawSColor, canvasDrawS, canvasDrawSContext, uplDrawSign,
            vueCanvasDrawI, imgDrawI, imgDrawIColor, canvasDrawI, canvasDrawIContext, uplDrawInit,
            uplAttachmentError, getAttachment, myAttachment, 
            hasComboFullLegalName, hasComboDesignation, hasComboOrganization, hasComboNric, hasComboPassport, hasComboDateSigned, comboData, validCombo, 
            downloadDoc, downloadAuditLog, 
            showBoxAction, showBoxShredApproval, showBoxInfo, showBoxObj, showBoxSignee, showBoxOther, showBoxActivity, showBoxDownload, showBoxDelegate, showBoxRemind, showBoxSpam, 
            dateReminder, setDateReminder, handlePdfRendered, zoomDefault, zoomIn, zoomOut, scale, getPosition, editPage, selectText, 
            isEncrypted, shredReject, shredApprove, shredCancelReject, browserWidth, docWidth, cancelSignReject, arrRejectedBySignee, delegate, 
            previewCombo, previewComboData, previewComboObj, selectDataSignature, dataSignatureId, hasComboStructureSign, hasComboStructureOverlap, dateSignIdSample
        }
    },
    data () {
        return {
            transX: 0,
            transY: 0,
            mySignatures: [],  
            myInitials: [], 
            myStamps: [] 
        }
    },
    mounted () {
        // Initiate tooltip
        // Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
        // Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

        const pondS = document.querySelector('.signature')
        pondS.addEventListener('FilePond:addfile', (e) => {
            // console.log('File added', e.detail)
            // console.log('old mounted: File error', e.detail.error)
            // console.log('old mounted: File file', e.detail.file)
            // console.log('old mounted: File filesize', e.detail.file.fileSize)

            if (e.detail.error === null) {
                this.$refs.pondS.removeFile(e.detail.file) 
            }
        })

        const pondI = document.querySelector('.initial')
        pondI.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                this.$refs.pondI.removeFile(e.detail.file) 
            }
        })

        const pondSt = document.querySelector('.stamp')
        pondSt.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                this.$refs.pondSt.removeFile(e.detail.file) 
            }
        })
    },
    methods: {
        
        handleSignaturePondInit (){
            this.$refs.pondS.getFiles()

        },
        handleInitialPondInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            this.$refs.pondI.getFiles()
        },
        handleStampPondInit (){
            this.$refs.pondSt.getFiles()
        },
        handleAttachmentUploadInit (){
            this.$refs.pondAttachment.getFiles()
        }
    }
}
</script>

<style>
body {
    color: #6E777E;
    font-size: 14px;
    overflow: hidden;
}

.divHover {
    padding: 5px; 
    /* background-color: var(--auth-box-bg); */
    background-color: var(--bgcolor-light-color);
    /* background-color: var(--bgcolor-light-hover-color); */
    /* border: 1px solid red; */
}

.divHover:hover {
    background-color: var(--bgcolor-light-hover-color);
    border: 2px solid #0dcaf0;
}

.divSelected {
    /* background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; */
    /* background-color: rgba(0, 0, 0, 0.075); */
    border: 2px solid #0dcaf0;
}

#auditLogBox {
    max-height: 300px;
    overflow: scroll;
}

.pdfBg {
    background-image: url("../../assets/bgTrans1.png");
    width: 100%;
}

.parent {
   /*  width: 100%;
    top: 0px;
    left: 0px;
    position: relative; */
    user-select: none;
}

.box {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
    border-radius: 5px;
}

.box:hover {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
}

.box:active {
    cursor: pointer;
    border-style: dashed;
    border-width: 3px;
}

.boxSign {
    border-style: double;
    border-width: 3px;
    cursor: nw-resize;
    border-radius: 5px;
}

.boxSign:hover {
    border-style: double;
    border-width: 3px;
}

.boxSign:active {
    border-style: double;
    border-width: 3px;
}

.boxSignDef {
    border-style: double;
    border-width: 3px;
    cursor: default;
    border-radius: 5px;
}

.boxStamp {
    border-style: double;
    border-width: 3px;
    cursor: nw-resize;
    border-radius: 5px;
}

.boxStamp:hover {
    border-style: double;
    border-width: 3px;
}

.boxStamp:active {
    border-style: double;
    border-width: 3px;
}

.boxStampDef {
    border-style: double;
    border-width: 3px;
    cursor: default;
    border-radius: 5px;
}

.boxInitial {
    border-style: double;
    border-width: 3px;
    cursor: nw-resize;
    border-radius: 5px;
}

.boxInitial:hover {
    border-style: double;
    border-width: 3px;
}

.boxInitial:active {
    border-style: double;
    border-width: 3px;
}

.boxInitialDef {
    border-style: double;
    border-width: 3px;
    cursor: default;
    border-radius: 5px;
}

.boxCombo {
    border-style: double;
    border-width: 3px;
    border-radius: 5px;
}

.boxCombo:hover {
    border-style: double;
    border-width: 3px;
}

.boxCombo:active {
    border-style: double;
    border-width: 3px;
}

.boxComboDef {
    border-style: double;
    border-width: 3px;
    cursor: default;
    border-radius: 5px;
}

.boxSignDef span.resizeIcon, .boxSign span.resizeIcon,
.boxInitialDef span.resizeIcon, .boxInitial span.resizeIcon,
.boxStampDef span.resizeIcon, .boxStamp span.resizeIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

@media (max-width: 575.98px) {
    .boxSign > div > div> svg, .boxSign > div > div> span, 
    .boxInitial > div > div> svg, .boxInitial > div > div> span {
        font-size: 7px;
    }

    .boxSignDef > div > div > span, .boxInitialDef > div > div > span {
        font-size: 7px;
    }

    .boxSignDef > div > div > svg, .boxInitialDef > div > div > svg {
        font-size: 7px;
        float: top;
    } 
}

.boxDisabled {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
    border-radius: 5px;
}
.boxDisabled:hover {
    border-style: dashed;
    border-width: 3px;
}
.boxDisabled:active {
    border-style: dashed;
    border-width: 3px;
}

@media (max-width: 575.98px) {
    .boxDisabled   {
        font-size: 7px;
    }
    .boxDisabled > div > svg, .boxSignInitial > div > svg, .boxSignInitialDef > div > svg {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
    .boxDisabled > div > span, .boxSignInitial > div > span, .boxSignInitialDef > div > span {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
}

.boxSelected {
    border-width: 4px;
    border-style: solid;
}
.boxCal {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}

.step-number {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    width: 22px;
    height: 22px;
    padding: 8px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: #FFFFFF;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: var(--bs-primary);
    cursor: pointer;
    margin-right: 2px;
}

.color0 {
    color: #1141AF;
    border-color: #1141AF;
}
.color0Bg {
    color: #1141AF;
    border-color: #1141AF;
    background-color: rgba(17, 65, 175, 0.2);
}

.color1 {
    color: #CD0275;
    border-color: #CD0275;
}
.color1Bg {
    color: #CD0275;
    border-color: #CD0275;
    background-color: rgba(205, 2, 117, 0.2);
}

.color2 {
    color: #00999D;
    border-color: #00999D;
}
.color2Bg {
    color: #00999D;
    border-color: #00999D;
    background-color: rgba(0, 153, 157, 0.2);
}

.color3 {
    color: #FF7302;
    border-color:  #FF7302;
}
.color3Bg {
    color: #FF7302;
    border-color:  #FF7302;
    background-color: rgba(255, 115, 2, 0.2);
}

.color4 {
    color: #AC6BC6;
    border-color:  #AC6BC6;
}
.color4Bg {
    color: #AC6BC6;
    border-color:  #AC6BC6;
    background-color: rgba(172, 107, 198, 0.2);
}

.color5 {
    color: #967218;
    border-color:  #967218;
}
.color5Bg {
    color: #967218;
    border-color:  #967218;
    background-color: rgba(243, 200, 91, 0.2);
}

.color6 {
    color: #7F7F7F;
    border-color:  #7F7F7F;
}
.color6Bg {
    color: #7F7F7F;
    border-color:  #7F7F7F;
    background-color: rgba(191, 191, 191, 0.2);
}

.color7 {
    color: #DF0808;
    border-color:  #DF0808;
}
.color7Bg {
    color: #DF0808;
    border-color:  #DF0808;
    background-color: rgba(241, 137, 137, 0.2);
}

.color8 {
    color: #1E90FF;
    border-color:  #1E90FF;
}
.color8Bg {
    color: #1E90FF;
    border-color:  #1E90FF;
    background-color: rgba(173, 216, 230, 0.2);
}

.color9 {
    color: #008000;
    border-color:  #008000;
}
.color9Bg {
    color: #008000;
    border-color:  #008000;
    background-color: rgba(142, 249, 142, 0.2);
}

.color10 {
    color: #9B497E;
    border-color:  #9B497E;
}
.color10Bg {
    color: #9B497E;
    border-color:  #9B497E;
    background-color: rgba(237, 162, 211, 0.2);
}

.toolboxBtnClose {
    position: fixed; 
    margin-top: 6px;
    width: 20px; 
    height: 45px; 
    padding: 11px 2px; 
    background-color: grey;
    border-top-right-radius: 8px; 
    border-bottom-right-radius: 8px; 
    z-index: 9; 
    opacity: 0.8; 
    cursor: pointer;
}

.toolboxBtnOpen {
    position: fixed;
    margin-top: 6px;
    left: 251px; 
    width: 20px; 
    height: 45px; 
    padding: 11px 2px; 
    background-color: grey;
    border-top-right-radius: 8px; 
    border-bottom-right-radius: 8px; 
    z-index: 9; 
    opacity: 0.8; 
    cursor: pointer;
}

.handle {
    cursor: nw-resize;
}

.card {
    background-color: var(--toolbar-bg);
    border: 1px solid var(--toolbar-border);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.border-bottom {
    border-bottom: 1px solid var(--toolbar-hr) !important;
}

.toolbarSignFloat {
    position: fixed; 
    z-index: 2; 
    top: 41px;
    width: 255px; 
    /* opacity: 0.8;  */
}

.toolbarSolid {
    opacity: 1;
}

.toolbarSemi {
    opacity: 0.8;
}


@font-face {
    font-family: 'MaShanZheng';
    src: url(/fontFamily/Ma_Shan_Zheng/MaShanZheng-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'SourceSerifPro';
    src: url(/fontFamily/Source_Serif_Pro/SourceSerifPro-Regular.ttf);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSerifPro';
    src: url(/fontFamily/Source_Serif_Pro/SourceSerifPro-Bold.ttf);
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DancingScript';
    src: url(/fontFamily/Dancing_Script/DancingScript-VariableFont_wght.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(/fontFamily/Poppins/Poppins-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'LuxuriousScript';
    src: url(/fontFamily/Luxurious_Script/LuxuriousScript-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'MarckScript';
    src: url(/fontFamily/Marck_Script/MarckScript-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'PetitFormalScript';
    src: url(/fontFamily/Petit_Formal_Script/PetitFormalScript-Regular.ttf);
    font-display: swap;
}

@font-face {
  font-family: 'PinyonScript';
  src: url(/fontFamily/Pinyon_Script/PinyonScript-Regular.ttf);
}

@font-face {
  font-family: 'ClickerScript';
  src: url(/fontFamily/Clicker_Script/ClickerScript-Regular.ttf);
}

@font-face {
  font-family: 'ImperialScript';
  src: url(/fontFamily/Imperial_Script/ImperialScript-Regular.ttf);
}

@font-face {
  font-family: 'MeieScript';
  src: url(/fontFamily/Meie_Script/MeieScript-Regular.ttf);
}

@font-face {
  font-family: 'RougeScript';
  src: url(/fontFamily/Rouge_Script/RougeScript-Regular.ttf);
}

@font-face {
  font-family: 'StyleScript';
  src: url(/fontFamily/Style_Script/StyleScript-Regular.ttf);
}

</style>