<template>
    <span>
        <img :src="signSrc" :width="signWidth" :height="signHeight" :class="signClass" :alt="signLabel" :style="signStyle"/>
    </span>
</template>

<script>
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import funcs from '@/functions/function'

export default {
    name: 'ImgSign',
    components: { Popper },
    props: ['id', 'width', 'height', 'label', 'style'],
    setup (props) {
        const axios = inject('axios')
        const route = useRoute()
        const func = funcs

        const signId = ref(props.id)
        const signSrc = ref(null) 

        const signWidth = ref(props.width)
        const signHeight = ref(props.height)
        const signLabel = ref(props.label)
        const signStyle = ref(props.style)
        const signClass = ref(props.class)

        onMounted(async () => {

            if (signClass.value === '' || signClass.value === null || signClass.value === undefined) {
                signClass.value = 'img-fluid'
            }

            if(signLabel.value === 'stamp') {
                // console.info('- - - stamp Imgsign', signId.value)
            }
            
            axios.get( '/user/signature/' + signId.value, { responseType: 'arraybuffer' }
                ).then((res) => {
                    if(signLabel.value === 'stamp') {
                        // console.info('res: ' + signId.value, res)
                    }

                    if(res.data) {
                        /* --- orig: cannot show img > 200kb
                        const b64 = btoa(String.fromCharCode(...new Uint8Array(res.data)))
                        const imgData = 'data:' + res.headers['content-type'] + ';base64,' + b64
                        signSrc.value = imgData */

                        const uint8Array = new Uint8Array(res.data)
                        // Convert the Uint8Array to a binary string
                        let binaryString = ''
                        uint8Array.forEach(byte => {
                            binaryString += String.fromCharCode(byte)
                        })
                        // Convert the binary string to a Base64 encoded string
                        signSrc.value = 'data:' + res.headers['content-type'] + ';base64,' + window.btoa(binaryString)

                        /* if(signLabel.value === 'stamp') {
                            console.info('- - - signSrc ' + signId.value, signSrc.value)
                        } */
                    }
                })
                .catch((error) => {
                    if(signLabel.value === 'stamp') {
                        console.info('- - - ERROR ' + signId.value, error.code, error.status, error.message, error.data, error)
                    }
                    func.addLog('comp: imgsign', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        })

        return { 
            route, signId, signSrc, signWidth, signHeight, signLabel, signStyle, signClass
        }
    }
}
</script>

<style>

</style>